import {
    Grid,
    GridProps,
    InternalStandardProps,
    Paper,
    Theme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import {
    audioCategoryTitleToString,
    checkIfBatchIncludesIbos,
} from "JS/Helpers";
import { Category, Content } from "JS/Models";
import { useGlobalRecommendations } from "JS/React/Hooks/Recommendations";
import { useRouting } from "JS/React/Hooks/Routes";
import { useThemeColors } from "JS/React/Hooks/ThemeColors";
import { useHistory } from "react-router-dom";
import { AudioCategoryTitle } from "..";
import { useEffect } from "react";

type PaperProps = {
    bgColor: string[];
};

const Item = styled(Paper)<PaperProps>(({ theme, bgColor }) => ({
    ...(bgColor && {
        background: `linear-gradient(180deg, ${bgColor.join(",")})`,
    }),
    ...theme.typography.body1,
    padding: theme.spacing(1),
    textAlign: "left",
    minHeight: "10rem",
    color: theme.palette.grey[200],
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "end",
    borderRadius: "0",
    boxShadow: "none",
    cursor: "pointer",
}));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        categoryTextWarpper: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "90%",
        },
    }),
);

export interface AudioCategoryListProps
    extends InternalStandardProps<GridProps> {
    categorizedAudios: Category[];
    recommendations: Content[];
}

export const AudioCategoryList = (props: AudioCategoryListProps) => {
    const classes = useStyles(props);

    const { categorizedAudios, recommendations } = props;

    const {
        handleListingNavigation,
        handleRecommendationNavigation,
        batchHasIbos,
    } = useHandlers();
    const { darkToneColors, lightToneColors, mediumToneColors } =
        useThemeColors();

    const showRecommendations =
        recommendations && recommendations.length > 0 && batchHasIbos;

    const cateWithOutRecom = Object.values(AudioCategoryTitle).filter(
        (x) =>
            x !== AudioCategoryTitle.RECOMMENDED &&
            x !== AudioCategoryTitle.INDEX,
    );

    const getBackGroundColor = (idx: number): string[] => {
        if (showRecommendations) {
            return (cateWithOutRecom.length + idx + 1) % 2 === 0
                ? lightToneColors
                : mediumToneColors;
        } else {
            return (cateWithOutRecom.length + idx) % 2 === 0
                ? lightToneColors
                : mediumToneColors;
        }
    };

    return (
        <Grid container spacing={0.2}>
            {cateWithOutRecom.map((item, idx) => (
                <Grid
                    item={true}
                    xs={idx === 0 ? 12 : 6}
                    md={3}
                    key={item}
                    onClick={() => handleListingNavigation(item)}
                >
                    <Item
                        bgColor={
                            idx === 0
                                ? darkToneColors
                                : idx % 2 === 0
                                ? lightToneColors
                                : mediumToneColors
                        }
                    >
                        {audioCategoryTitleToString(item)}
                    </Item>
                </Grid>
            ))}

            {showRecommendations && (
                <Grid
                    item={true}
                    xs={6}
                    md={3}
                    onClick={handleRecommendationNavigation}
                >
                    <Item
                        bgColor={
                            cateWithOutRecom.length % 2 === 0
                                ? lightToneColors
                                : mediumToneColors
                        }
                    >
                        {audioCategoryTitleToString(
                            AudioCategoryTitle.RECOMMENDED,
                        )}
                    </Item>
                </Grid>
            )}

            {categorizedAudios &&
                categorizedAudios.length > 0 &&
                categorizedAudios
                    .filter((d) => d.category_title !== "All Audios")
                    .map((item, idx) => (
                        <Grid
                            item={true}
                            xs={6}
                            md={3}
                            key={item.category_id}
                            onClick={() =>
                                handleListingNavigation(item.category_id)
                            }
                        >
                            <Item bgColor={getBackGroundColor(idx)}>
                                <div
                                    className={clsx(
                                        classes.categoryTextWarpper,
                                    )}
                                >
                                    {item.category_title}
                                </div>
                            </Item>
                        </Grid>
                    ))}
        </Grid>
    );
};

const useHandlers = () => {
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { recommendationState } = useGlobalRecommendations();

    const handleListingNavigation = (id: string) => {
        history.push(linkProvider.react.audios().listing(id));
    };

    const handleRecommendationNavigation = () => {
        history.push(linkProvider.react.recommendations().index());
    };

    return {
        batchHasIbos: checkIfBatchIncludesIbos(recommendationState.batch),
        handleListingNavigation,
        handleRecommendationNavigation,
    };
};
