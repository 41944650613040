/* eslint-disable no-loop-func */
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Grid,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { MediaPlayer } from "JS/React/Components/MediaPlayer";
import { GraphicEq as GraphicEqIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OfflineBundle } from "JS/Models/Common";
import {
    Content,
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
    Gifts,
    ResumeContent,
} from "JS/Models";
import { useGlobalAudios } from "JS/React/Hooks/Audio";
import {
    useGlobalGifts,
    useReceivedMemberAudioGifts,
} from "JS/React/Hooks/Gifts";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import qs from "qs";
import moment from "moment";
import { asyncSortArrayByNumberKey, sortArrayByKey } from "JS/Helpers";
import { getDexieConnectionRefresh } from "JS/Database/Dexie";
import FallbackWaveform from "Images/Content/audio-waves.png";
import { IndexableTypeArray, IndexableTypePart } from "dexie";
import {
    useContentPlayingHandlers,
    usePlayerHandlers,
} from "JS/React/Hooks/MediaPlayer";
import { useMiniPlayerHandlers } from "JS/React/Hooks/MiniPlayer";
import { PlayerActionBtns } from "JS/React/Components/MediaPlayer/PlayerActionBtns";
import { PLAYER_PROGRESS_INTERVAL } from "JS/Helpers/Contants";
import {
    SentGiftType,
    getPlayerPlayedTimeForBundle,
    toSentOfflineGift,
} from "JS/Models/Firebase/GiftDashboard";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";

export interface BundlePlayerOfflineProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const BundlePlayerOffline = (props: BundlePlayerOfflineProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const params: any = useParams();
    const { contentId } = params;
    const [mediaURL, setMediaURL] = useState("");
    const [audio, setAudio] = useState<OfflineBundle>();

    const location = useLocation();

    const bundlePlayerRef = useRef<any>();
    const { handleChangeSeek } = usePlayerHandlers(bundlePlayerRef);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [isReadyToPlay, setIsReadyToPlay] = useState<boolean>(false);
    const [currentPlayingState, setCurrentPlayingState] = useState(true);
    const { handlePlayerPause, handlePlayerPlay } = useContentPlayingHandlers(
        setCurrentPlayingState,
    );
    const { miniPlayer } = useMiniPlayerHandlers();

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { index = 0 } = parsedQueryString;

        return {
            index: index.toString(),
        };
    }, [location?.search]);

    const mediaIndex = +queryParams.index;
    const history = useHistory();
    const setQueryParams = (params: Partial<typeof queryParams>) => {
        history.replace({
            pathname: location.pathname,
            search: qs.stringify({ ...queryParams, ...params }),
        });
    };

    const {
        audios,
        resumeUserBundles,
        setGlobalAudios,
        updateRecentlyPlayed,
        updateResumeBundles,
    } = useGlobalAudios();
    const {
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
        setGlobalGifts,
    } = useGlobalGifts();
    const media = audio?.media;
    const isBundle = media && media?.length > 1;

    const downloadedAudioDetail = useMemo(() => {
        const findFrom: (Gifts | Content)[] = [];
        if (audios) findFrom.push(...audios);
        if (receivedMemberAudioGifts)
            findFrom.push(...receivedMemberAudioGifts);
        if (receivedProspectAudioGifts)
            findFrom.push(...receivedProspectAudioGifts);

        return [...findFrom]?.find((x) => x.sku_id === audio?.skuId);
    }, [audios, audio]);

    const giftDashboardData = useMemo(() => {
        return toSentOfflineGift(audio, mediaIndex);
    }, [audio, mediaIndex]);

    const getDashboardActionDetails = () => {
        return getPlayerPlayedTimeForBundle(bundlePlayerRef, mediaIndex + 1);
    };
    const { isGift } = useReceivedMemberAudioGifts(true);
    const isReceivedMemberAudio = isGift(contentId.split("-")[1]);

    const { logGiftCompleted } = useGiftDashboard(
        giftDashboardData,
        SentGiftType.AUDIO,
        (downloadedAudioDetail as Gifts)?.sender?.member_id,
        isReceivedMemberAudio && currentPlayingState && !miniPlayer.url,
        getDashboardActionDetails,
        true,
    );

    const next = () => {
        setIsReady(false);
        setIsReadyToPlay(false);
        setCurrentPlayingState(true);
        setQueryParams({
            index: ((mediaIndex + 1) % media?.length).toString(),
        });
    };

    const prepareBundle = async (contentId: string) => {
        const bundleCollection = getDexieConnectionRefresh().audios.filter(
            (audio) => audio.isBundle === true,
        );
        let availableBundlePartsNids: string[] = [];
        const splittedContentId = contentId.split("-");
        const id = `${splittedContentId[0]}-${splittedContentId[1]}`;
        let audio: OfflineBundle = {};

        await bundleCollection.keys().then((res) => {
            if (res && res.length) {
                let downloadedBundlePartsNids: IndexableTypeArray = res;
                downloadedBundlePartsNids.forEach((nid: IndexableTypePart) => {
                    if (nid?.toString()?.startsWith(id)) {
                        availableBundlePartsNids.push(nid?.toString());
                    }
                });
            }
        });

        for (let i = 0; i < availableBundlePartsNids.length; i++) {
            await getDexieConnectionRefresh()
                .audios.get(availableBundlePartsNids[i])
                .then(async (res) => {
                    if (res) {
                        if (Object.keys(audio).length) {
                            const mediaArray = Object.assign([], audio.media);
                            audio = {
                                ...audio,
                                media: [
                                    ...mediaArray,
                                    {
                                        media_title: res.media_title,
                                        position: res.position,
                                        blob: res.blob,
                                    },
                                ],
                            };
                        } else {
                            audio = {
                                id,
                                name: res.name,
                                media: [
                                    {
                                        media_title: res.media_title,
                                        position: res.position,
                                        blob: res.blob,
                                    },
                                ],
                                skuId: res.skuId,
                                description: res.description,
                                release_date: res.release_date,
                            };
                        }
                    }
                });
            const sortedMedia = await asyncSortArrayByNumberKey(
                [...audio.media],
                "position",
                "ASC",
            );
            audio = {
                ...audio,
                media: sortedMedia,
            };
            if (i === availableBundlePartsNids.length - 1) {
                setAudio(audio);
            }
        }
    };
    useEffect(() => {
        if (contentId) {
            prepareBundle(contentId);
        }
    }, [contentId]);

    const previous = () => {
        setIsReady(false);
        setIsReadyToPlay(false);
        setCurrentPlayingState(true);
        setQueryParams({
            index: (mediaIndex === 0
                ? media?.length - 1
                : mediaIndex - 1
            ).toString(),
        });
    };
    const onComplete = () => {
        const data: ResumeContent = {
            nid: audio.id,
            sku_id: audio.skuId,
            title: media[mediaIndex].media_title,
            lastPlayed: bundlePlayerRef.current.getCurrentTime(),
            maximumPlayed: bundlePlayerRef.current.getCurrentTime(),
            lastCompletedTime: bundlePlayerRef.current.getCurrentTime(),
            duration: bundlePlayerRef.current.getDuration(),
        };
        if (isReceivedMemberAudio) {
            const actionsDetails = getDashboardActionDetails();
            logGiftCompleted({ index: actionsDetails.index });
        }
        updateResumeBundles(data);
        if (mediaIndex < media?.length - 1) next();
    };
    const { logFirebaseEvent } = useFirebaseLogger();

    useEffect(() => {
        if (media && media[mediaIndex]) {
            setMediaURL(URL.createObjectURL(media[mediaIndex]?.blob));
        }
    }, [audio?.id, media, mediaIndex]);

    const onMediaPlayerReady = useCallback(() => {
        setIsReadyToPlay(true);
        if (!isReady && audio && media[mediaIndex]?.media_title) {
            const nid = audio?.id?.split("-")[1]
                ? audio?.id?.split("-")[1]
                : "";

            const resumeData: ResumeContent = resumeUserBundles
                ? resumeUserBundles.find(
                      (x) =>
                          x.nid === nid &&
                          x.title === media[mediaIndex]?.media_title,
                  )
                : ({} as ResumeContent);
            if (
                resumeData &&
                Math.ceil(+resumeData.lastPlayed) !==
                    Math.ceil(+resumeData.duration)
            ) {
                bundlePlayerRef.current.seekTo(
                    resumeData.lastPlayed,
                    "seconds",
                );
            }

            const data: ResumeContent = {
                nid: nid,
                sku_id: audio.skuId,
                title: media[mediaIndex]?.media_title,
                lastPlayed: bundlePlayerRef.current.getCurrentTime(),
                maximumPlayed: bundlePlayerRef.current.getCurrentTime(),
                duration: bundlePlayerRef.current.getDuration(),
            };

            updateResumeBundles(data);
            handleRecentlyPlayed();
            setIsReady(true);
        }
    }, [isReady, audio?.id, media]);

    const handleAudioStartedPlaying = () => {
        if (audio) {
            const { id, name: sku } = audio;
            const nid = id?.split("-")[1] ? id?.split("-")[1] : "";

            const action: FirebaseEventAction = {
                action: EventActions.PLAY,
                nId: nid,
                contentTitle: media[mediaIndex].media_title,
                skuId: sku,
                category: EventCategories.AUDIOS,
            };

            logFirebaseEvent(EventNames.AUDIO_PLAYED, action);
            const data: ResumeContent = {
                nid: nid,
                sku_id: audio.skuId,
                title: media[mediaIndex].media_title,
                lastPlayed: bundlePlayerRef.current.getCurrentTime(),
                maximumPlayed: bundlePlayerRef.current.getCurrentTime(),
                duration: bundlePlayerRef.current.getDuration(),
            };
            updateResumeBundles(data);
            handleRecentlyPlayed();
        }
    };

    const onPlayerProgress = async () => {
        if (audio) {
            const nid = audio?.id?.split("-")[1]
                ? audio?.id?.split("-")[1]
                : "";
            const data: ResumeContent = {
                nid: nid,
                sku_id: audio.skuId,
                title: media[mediaIndex].media_title,
                lastPlayed: bundlePlayerRef.current.getCurrentTime(),
                maximumPlayed: bundlePlayerRef.current.getCurrentTime(),
            };
            updateResumeBundles(data);
        }
    };

    const handleRecentlyPlayed = () => {
        const timeStamp = moment().unix().toString();
        const nid = contentId?.split("-")[1] ? contentId?.split("-")[1] : "";
        let audio: Content | Gifts;

        if (audios?.filter((a) => a.nid === nid).length > 0) {
            const toSaveAudios: Content[] = audios.map((d) => {
                if (d.nid === nid) {
                    audio = {
                        ...d,
                        played: timeStamp,
                    };
                    return audio;
                } else {
                    return d;
                }
            });
            setGlobalAudios(toSaveAudios);
        } else if (
            receivedMemberAudioGifts?.filter((video) => video.nid === nid)
                .length > 0
        ) {
            const toSaveReceivedMembers: Gifts[] = receivedMemberAudioGifts.map(
                (d) => {
                    if (d.nid === nid) {
                        audio = {
                            ...d,
                            played: timeStamp,
                        };
                        return audio;
                    } else {
                        return d;
                    }
                },
            );
            setGlobalGifts({
                receivedMemberAudioGifts: sortArrayByKey(
                    toSaveReceivedMembers,
                    "title",
                    "ASC",
                ),
            });
        } else {
            const toSaveProspectsMembers: Gifts[] =
                receivedProspectAudioGifts.map((d) => {
                    if (d.nid === nid) {
                        audio = {
                            ...d,
                            played: timeStamp,
                        };
                        return audio;
                    } else {
                        return d;
                    }
                });
            setGlobalGifts({
                receivedProspectAudioGifts: sortArrayByKey(
                    toSaveProspectsMembers,
                    "title",
                    "ASC",
                ),
            });
        }

        updateRecentlyPlayed({ ...audio, played: timeStamp });
    };

    const handleBackNavigation = () => {
        history.goBack();
    };

    const [isLoop, setLoop] = useState(false);

    return (
        <>
            {media && media[mediaIndex] && (
                <div {...rest} className={clsx(className, classes.root)}>
                    <AppHeader
                        title={media[mediaIndex].media_title}
                        canGoBack
                        searchable={false}
                        onBackClick={handleBackNavigation}
                    />
                    <Grid container className={clsx(classes.root, className)}>
                        {audio && (
                            <>
                                <Grid
                                    container
                                    xs={12}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    {isBundle ? (
                                        <>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent={
                                                            "space-between"
                                                        }
                                                    >
                                                        <Box
                                                            display={"flex"}
                                                            alignItems="center"
                                                            className={
                                                                classes.forwardBackItem
                                                            }
                                                            onClick={previous}
                                                        >
                                                            <FontAwesomeIcon
                                                                className={clsx(
                                                                    classes.fwdBackIcon,
                                                                    classes.backIcon,
                                                                )}
                                                                icon={[
                                                                    "fas",
                                                                    "chevron-left",
                                                                ]}
                                                            />
                                                            <Typography
                                                                className={
                                                                    classes.fwdBackTypo
                                                                }
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                                variant="h5"
                                                            >
                                                                Previous
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            display={"flex"}
                                                            alignItems="center"
                                                            className={
                                                                classes.forwardBackItem
                                                            }
                                                            onClick={next}
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.fwdBackTypo
                                                                }
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                                variant="h5"
                                                            >
                                                                Next
                                                            </Typography>
                                                            <FontAwesomeIcon
                                                                className={clsx(
                                                                    classes.fwdBackIcon,
                                                                    classes.fwdIcon,
                                                                )}
                                                                icon={[
                                                                    "fas",
                                                                    "chevron-right",
                                                                ]}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className={
                                                    classes.playerContainer
                                                }
                                            >
                                                <MediaPlayer
                                                    title={
                                                        media[mediaIndex]
                                                            .media_title
                                                    }
                                                    playerRef={bundlePlayerRef}
                                                    progressInterval={
                                                        PLAYER_PROGRESS_INTERVAL
                                                    }
                                                    onProgress={
                                                        onPlayerProgress
                                                    }
                                                    onStart={
                                                        handleAudioStartedPlaying
                                                    }
                                                    onReady={onMediaPlayerReady}
                                                    mediaURL={mediaURL}
                                                    imageURL={FallbackWaveform}
                                                    onEnded={onComplete}
                                                    loop={isLoop}
                                                    isAudio={true}
                                                    onNext={next}
                                                    onPrevious={previous}
                                                    playing={
                                                        currentPlayingState &&
                                                        !miniPlayer.url
                                                    }
                                                    onPlay={handlePlayerPlay}
                                                    onPause={handlePlayerPause}
                                                    onUpdateExpiredUrl={() => {
                                                        setIsReady(false);
                                                        setIsReadyToPlay(true);
                                                    }}
                                                    onError={() => {
                                                        setIsReadyToPlay(true);
                                                    }}
                                                    loading={!isReadyToPlay}
                                                />
                                            </Grid>
                                            <Box
                                                display={"flex"}
                                                width={"100%"}
                                                justifyContent={"center"}
                                            >
                                                <PlayerActionBtns
                                                    handleChangeSeek={
                                                        handleChangeSeek
                                                    }
                                                    onClickLoop={() =>
                                                        setLoop(!isLoop)
                                                    }
                                                    isLoop={isLoop}
                                                />
                                            </Box>
                                        </>
                                    ) : (
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"flex-end"}
                                            className={classes.dummyImageBox}
                                        >
                                            <span
                                                className={
                                                    classes.graphicWrapper
                                                }
                                            >
                                                <GraphicEqIcon
                                                    className={
                                                        classes.graphicIcon
                                                    }
                                                    sx={{
                                                        fontSize: "60px",
                                                    }}
                                                />
                                            </span>
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.grey800}
                                        variant="body2"
                                    >
                                        {audio.description}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </div>
            )}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },

        grey500: {
            color: theme.palette.grey[500],
        },
        grey800: {
            color: theme.palette.grey[800],
        },
        greyA100: {
            color: theme.palette.grey.A100,
        },
        spinner: {
            color: theme.palette.primary.main,
        },
        icon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        constMargin: {
            marginRight: theme.spacing(2),
        },
        playerContainer: {
            margin: theme.spacing(2, 0),
            height: "60vh",
            [theme.breakpoints.down("lg")]: {
                height: "50vh",
            },
            [theme.breakpoints.down("sm")]: {
                height: "30vh",
            },
        },
        graphicIcon: {
            color: theme.palette.grey[400],
        },
        graphicWrapper: {
            border: `5px solid ${theme.palette.common.white}`,
            borderRadius: "50%",
            height: "120px",
            width: "120px",
            marginRight: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        dummyImageBox: {
            position: "relative",
            background: theme.palette.grey[900],
            height: "100%",
        },
        mediaImage: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            background: theme.palette.common.black,
        },
        date: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        transactionCompletePaper: {
            color: theme.palette.grey[600],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "120px",
        },
        transactionConfirmation: {
            color: theme.palette.grey[600],
            minHeight: "120px",
        },
        giftQuanityWrapper: {
            background: `${theme.palette.grey[300]} !important`,
            color: theme.palette.grey[600],
            borderRadius: "50%",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(0.5),
            minWidth: "25px",
            minHeight: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
        },
        giftSectionWarpper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        },
        giftIconButtonDisabled: {
            opacity: "50%",
            cursor: "not-allowed !important",
        },
        giftIconPositioner: {
            position: "absolute",
            bottom: 10,
            right: 10,
        },
        metaData: {
            fontSize: "14px",
        },

        forwardBackItem: {
            padding: theme.spacing(2),
            color: theme.palette.grey.A100,
            cursor: "pointer",
        },
        fwdBackTypo: {
            fontSize: "18px",
        },
        fwdBackIcon: {
            fontSize: "18px",
        },
        backIcon: {
            marginRight: theme.spacing(2),
        },
        fwdIcon: {
            marginLeft: theme.spacing(2),
        },
        controlIcons: {
            minWidth: "40px",
            display: "flex",
        },
        playerIcon: {
            height: "35px",
        },
    }),
);
