import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRouting } from "./Routes";
import parse from "html-react-parser";
import { ConsentTextService } from "JS/Services/ConsentForm/ConsentFormTextService";
import { useFirebaseAuth } from "./Firebase/Auth";
import { useGlobalMediaEssentials } from "./MediaEssentials";
import { config } from "JS/Config";
import { messaging } from "JS/Helpers/UserMessaging";
import { ConsentStatusService } from "JS/Services/ConsentForm/ConsentStatusService";
import { useFirebaseLogger } from "./Firebase";
import { EventActions, EventNames } from "JS/Models";
import { captureSentryError } from "JS/Helpers/SentryHelper";
import { ConsentText } from "JS/Models/ConsentForm";
import {
    deleteConsentStatusById,
    getConsentStatusById,
    setConsentStatusById,
    updateConsentStatusById,
} from "JS/Database/ConsentForm";
import { ConsentFormDexie } from "JS/Database/Dexie";
import {
    getIsRedirectedToAmmwayConsentForm,
    getResetConsentIndexDbStatus,
    setResetConsentIndexDbStatus,
} from "JS/Helpers/LocalStorageHelpers";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import {
    resetRedirectionState,
    setLoadingState,
    setRedirectionState,
} from "JS/Redux/ConsentForm";

export const useConsentForm = () => {
    const service = new ConsentStatusService(config.user.memberId);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { logFirebaseEvent } = useFirebaseLogger();

    const consentPostFailed = (err) => {
        setLoading(false);
        enqueueSnackbar(messaging.common.error, {
            variant: "error",
        });
        captureSentryError(err, {
            location: `ConsentFormApiCMLM`,
        });
    };

    const consentGiven = () => {
        setLoading(true);
        service
            .postConsentToCMLM()
            .then((response) => {
                if (response?.status) {
                    getConsentStatusById(config.user.memberId)
                        .then((consentData) => {
                            if (consentData) {
                                updateConsentStatusById(config.user.memberId, {
                                    ltdConsentStatus: true,
                                } as ConsentFormDexie);
                                setSuccess(true);
                                logFirebaseEvent(
                                    EventNames.CONSENT_FORM_ACCEPTED,
                                    {
                                        action: EventActions.CONSENT_FORM,
                                    },
                                );
                            }
                        })
                        .catch((err) => {
                            captureSentryError(err, {
                                location: `ConsentFormIndexDB`,
                            });
                        })
                        .finally(() => setLoading(false));

                    const firestorePromise = service.postConsentToFirestore();
                    if (!!firestorePromise) {
                        firestorePromise
                            .then(() => {
                                //Nothing required here
                            })
                            .catch((err) =>
                                captureSentryError(err, {
                                    location: `PostConsentStatusFB`,
                                }),
                            );
                    }
                } else
                    consentPostFailed(
                        new Error("CMLM API response status is false"),
                    );
            })
            .catch((err) => {
                consentPostFailed(err);
            });
    };

    return {
        loading,
        success,
        consentGiven,
    };
};

export const useGetConsentText = () => {
    const service = new ConsentTextService();
    const [text, setText] = useState<string>(undefined);
    const [subHeader, setSubHeader] = useState<string>(undefined);
    const [header, setHeader] = useState<string>(undefined);
    const [loading, setLoading] = useState(false);
    const { logFirebaseEvent } = useFirebaseLogger();

    const setConsentTextManually = () => {
        const { subHeader, text, header } =
            messaging.consentForm.ltdConsentText;
        setSubHeader(subHeader);
        setText(parse(text));
        setHeader(header);
        logFirebaseEvent(EventNames.CONSENT_FORM_SHOWN, {
            action: EventActions.CONSENT_FORM,
        });
    };

    useEffect(() => {
        setLoading(true);
        service
            .getConsentFormText()
            .then((res) => {
                if (!!res) {
                    const consentText: ConsentText = JSON.parse(
                        res?.Body?.toString(),
                    );
                    setSubHeader(parse(consentText.consentSubheader));
                    setText(parse(consentText.consentText));
                    setHeader(consentText.consentHeader);
                    logFirebaseEvent(EventNames.CONSENT_FORM_SHOWN, {
                        action: EventActions.CONSENT_FORM,
                    });
                } else setConsentTextManually();
            })
            .catch((error) => {
                setConsentTextManually();
                captureSentryError(error, {
                    location: `ConsentForm`,
                });
            })
            .finally(() => setLoading(false));
    }, []);

    return {
        header,
        subHeader,
        text,
        loading,
    };
};

export const useOpenConsentForm = () => {
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { coursesFirebaseSignIn } = useFirebaseAuth();
    const { mediaEssentials } = useGlobalMediaEssentials();
    const dispatch = useAppDispatch();
    const service = new ConsentStatusService(config.user.memberId);
    const { logFirebaseEvent } = useFirebaseLogger();

    const redirectToLtdForm = () => {
        history.push(linkProvider.react.consentForm().index());
    };

    const openForm = async () => {
        if (!!mediaEssentials?.allow_consent_form_feature) {
            if (
                getResetConsentIndexDbStatus() !== "true" &&
                mediaEssentials?.missing_consent_member_ids?.includes(
                    config.user.memberId,
                )
            ) {
                deleteConsentStatusById(config.user.memberId);
                setResetConsentIndexDbStatus("true");
            }
            dispatch(setLoadingState({ loadingConsent: true }));

            getConsentStatusById(config.user.memberId)
                .then((consentData) => {
                    dispatch(setLoadingState({ loadingConsent: false }));
                    if (
                        !consentData ||
                        (consentData?.redirectToAmwayConsentForm !== false &&
                            getIsRedirectedToAmmwayConsentForm() !== "true")
                    ) {
                        dispatch(setLoadingState({ loadingConsent: true }));
                        service
                            .getConsentFromCMLM()
                            .then((response) => {
                                if (!response?.data?.response?.status) return;
                                const {
                                    consentStatus,
                                    redirectToAmwayConsentForm,
                                    ssoRedirectionPlace,
                                } = response?.data?.response?.data;

                                const updatedConsent: ConsentFormDexie = {
                                    id: config.user.memberId,
                                    ltdConsentStatus: consentStatus,
                                    redirectToAmwayConsentForm,
                                    ssoRedirectionPlace,
                                };

                                setConsentStatusById(updatedConsent);
                                dispatch(
                                    setRedirectionState({
                                        showRedirectionDialog:
                                            consentStatus &&
                                            redirectToAmwayConsentForm,
                                    }),
                                );

                                if (!consentStatus) redirectToLtdForm();
                                if (!redirectToAmwayConsentForm) {
                                    logFirebaseEvent(
                                        EventNames.CONSENT_FORM_V2_ACCEPTED,
                                        {
                                            action: EventActions.CONSENT_FORM_V2_ACCEPTED,
                                        },
                                    );
                                }
                            })
                            .catch((error) => {
                                dispatch(resetRedirectionState());
                                captureSentryError(error, {
                                    location: `ConsentFormApiCMLM`,
                                });
                                redirectToLtdForm();
                            })
                            .finally(() =>
                                dispatch(
                                    setLoadingState({
                                        loadingConsent: false,
                                    }),
                                ),
                            );
                    } else if (!consentData.ltdConsentStatus) {
                        redirectToLtdForm();
                    }
                })
                .catch((error) => {
                    dispatch(resetRedirectionState());
                    captureSentryError(error, {
                        location: `ConsentFormIndexDB`,
                    });
                });

            await coursesFirebaseSignIn();
            service
                .getConsentFromFirestore()
                .then((status) => {
                    if (!status?.consentStatus) {
                        const error = new Error(
                            `Get response of consent firestore is ${status?.consentStatus}`,
                        );
                        captureSentryError(error, {
                            location: `GetConsentStatusFB`,
                        });
                    }
                })
                .catch((error) => {
                    captureSentryError(error, {
                        location: `GetConsentStatusFB`,
                    });
                });
        }
    };

    return {
        openForm,
    };
};

export const useGetConsentStatusFromCMLM = (skip: boolean = false) => {
    const dispatch = useAppDispatch();
    const showRedirectionDialog = useAppSelector(
        (state) => state.consentForm.showRedirectionDialog,
    );
    const [showV2ConsentForm, setShowV2ConsentForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const service = new ConsentStatusService(config.user.memberId);

    const getConsentStatus = () => {
        setLoading(true);
        dispatch(setLoadingState({ loadingConsent: true }));
        service
            .getConsentFromCMLM()
            .then((response) => {
                if (!response?.data?.response?.status) return;

                const {
                    redirectToAmwayConsentForm,
                    consentStatus,
                    ssoRedirectionPlace,
                } = response?.data?.response?.data;
                const updatedConsent: ConsentFormDexie = {
                    id: config.user.memberId,
                    ltdConsentStatus: consentStatus,
                    redirectToAmwayConsentForm,
                    ssoRedirectionPlace,
                };

                setConsentStatusById(updatedConsent);
                if (!showRedirectionDialog && redirectToAmwayConsentForm) {
                    setShowV2ConsentForm(true);
                    dispatch(
                        setRedirectionState({ showRedirectionDialog: true }),
                    );
                } else {
                    setShowV2ConsentForm(false);
                }
            })
            .catch((error) => {
                dispatch(resetRedirectionState());
                captureSentryError(error, {
                    location: "ConsentFormApi",
                });
            })
            .finally(() => {
                dispatch(setLoadingState({ loadingConsent: false }));
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!skip) getConsentStatus();
    }, []);

    return {
        getConsentStatus,
        showV2ConsentForm,
        loading,
    };
};
