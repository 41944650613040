import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RetiredContent } from "JS/Models/RetiredContent";
export interface RetiredContentState {
    retiredContent: RetiredContent;
    isRetAudioUpdateRequired?: boolean;
    isRetVideoUpdateRequired?: boolean;
}

const initialState: RetiredContentState = {
    retiredContent: {
        MP3: [],
        Video: [],
        GiftProsMP3: [],
        GiftMP3: [],
        GiftVideo: [],
        GiftProsVideo: [],
        Course: [],
        GiftCourse: [],
    },
    isRetAudioUpdateRequired: false,
    isRetVideoUpdateRequired: false,
};

export const retiredContentStateSlice = createSlice({
    name: "retiredContentState",
    initialState,
    reducers: {
        setRetiredAudios: (state, data: PayloadAction<RetiredContent>) => {
            state = {
                retiredContent: {
                    ...state.retiredContent,
                    MP3: data.payload.MP3,
                    GiftMP3: data.payload.GiftMP3,
                    GiftProsMP3: data.payload.GiftProsMP3,
                },
                isRetAudioUpdateRequired: false,
            };
            return state;
        },

        setRetiredVideos: (state, data: PayloadAction<RetiredContent>) => {
            state = {
                retiredContent: {
                    ...state.retiredContent,
                    Video: data.payload.Video,
                    GiftVideo: data.payload.GiftVideo,
                    GiftProsVideo: data.payload.GiftProsVideo,
                },
                isRetVideoUpdateRequired: false,
            };
            return state;
        },

        setRetContentUpdateRequired: (state) => {
            return {
                retiredContent: { ...state.retiredContent },
                isRetAudioUpdateRequired: true,
                isRetVideoUpdateRequired: true,
            };
        },

        resetRetiredContentState: (state) => {
            return initialState;
        },
    },
});

export const {
    setRetiredAudios,
    setRetiredVideos,
    setRetContentUpdateRequired,
    resetRetiredContentState,
} = retiredContentStateSlice.actions;
