import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import {
    Box,
    BoxProps,
    Dialog,
    InternalStandardProps,
    Slide,
    Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { TransitionProps } from "@mui/material/transitions";

import {
    AddToCart,
    Content,
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
    Recommendations,
    RecommendationSource,
} from "JS/Models";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useRouting } from "JS/React/Hooks/Routes";
import {
    audioCategoryTitleToString,
    recPurchaseAnalyticsActionAndEvent,
    recRedeemAnalyticsActionAndEvent,
    restrictRecIdCountAndString,
} from "JS/Helpers";
import { TransactionConfirmDialog } from "JS/React/Components/ContentPurchase/TransactionConfirmDialog";
import { PaymentMethod } from "JS/React/Components/ContentPurchase/PaymentMethodRadio";
import {
    useAddContentToCart,
    useAvailableCredits,
    usePurchaseByCard,
    useRedeemByCredits,
} from "JS/React/Hooks/Purchase";
import {
    useGlobalBlackRecommendations,
    useGlobalRecommendations,
} from "JS/React/Hooks/Recommendations";
import { AppTypography } from "JS/React/Components/AppTypography";
import { AppConfimationDialog } from "JS/React/Components/AppConfirmationDialog";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { AppResponse } from "JS/Types";
import { RecommendationListItem } from "./RecommendationListItem";
import { AudioCategoryTitle } from "../Audios";
import { useSnackbar } from "notistack";

export interface RecommendationsDialogProps
    extends InternalStandardProps<BoxProps> {
    recommendedAudios: Content[];
    recommendations: Recommendations[];
    onPurchaseAudio: (nid: string) => void;
    isAuto: boolean;
    push: boolean;
    thumbnails: {
        [key: string]: string;
    };
}

export type RecommendationBtn = "Play" | "Redeem" | "Buy";

interface RecommendationAnalytics {
    recCount: number;
    recSource: RecommendationSource;
    onRecommendationsOpen: () => void;
    logRedeemAnalytics: (
        type: "request" | "failed" | "success",
        recommendation: Recommendations,
        recommendedAudio: Content,
        failureCause?: string,
    ) => void;
    logPurchaseAnalytics: (
        type: "request" | "failed" | "success" | "canceled" | "confirm-cancel",
        recommendation: Recommendations,
        recommendedAudio: Content,
        failureCause?: string,
    ) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const RecommendationsDialog = (props: RecommendationsDialogProps) => {
    const classes = useStyles(props);

    const {
        className,
        recommendedAudios,
        isAuto,
        push,
        thumbnails,
        onPurchaseAudio,
        ...rest
    } = props;

    const recAnalytics = useRecommendationAnalytics(props);
    useIndexHooks(props, recAnalytics?.onRecommendationsOpen);

    const {
        cart,
        transConfirmDialog,
        paymentMethod,
        activeRecommendedAudio,
        buying,
        redeemingContent,
        purchaseOnRedeemFailDialog,
        loadingCart,
        creditsLoading,
        onBackClick,
        onRowClick,
        onActionBtnClick,
        onCloseDialog,
        onTransactionConfirm,
        onClosePurchaseOnRedeemFailDialog,
    } = useHandlers(props, recAnalytics);

    return (
        <Box className={clsx(className, classes.root)} {...rest}>
            <TransactionConfirmDialog
                method={paymentMethod}
                open={transConfirmDialog}
                cart={cart}
                onClose={onCloseDialog}
                requiredCredits={activeRecommendedAudio?.number_of_tokens}
                totalPrice={`$${parseFloat(
                    `${activeRecommendedAudio?.content_price}`,
                ).toFixed(2)}`}
                onBuy={onTransactionConfirm}
                buying={buying}
                giftPurchasedEnabled={false}
            />
            <AppConfimationDialog
                open={purchaseOnRedeemFailDialog}
                popupMainText={messaging.dialogs.recRedeemFail}
                titleSize="small"
                confirmText="Yes"
                cancelButtonText="Cancel"
                onConfirm={() =>
                    onActionBtnClick("Buy", activeRecommendedAudio)
                }
                onClose={onClosePurchaseOnRedeemFailDialog}
            />

            <Dialog
                className={classes.dialog}
                scroll="body"
                fullScreen
                open={true}
                TransitionComponent={Transition}
            >
                <Box className={classes.dialogWrapper}>
                    <AppHeader
                        title={audioCategoryTitleToString(
                            AudioCategoryTitle.RECOMMENDED,
                        )}
                        onBackClick={onBackClick}
                        canGoBack={true}
                        searchable={false}
                    />
                    {(loadingCart || creditsLoading) && (
                        <AppBackdropProgress
                            open
                            backdropText={messaging.loader.processing}
                        />
                    )}
                    {recommendedAudios?.length === 0 && (
                        <AppTypography
                            className={classes.emptyList}
                            align="center"
                            variant="h6"
                        >
                            {messaging.common.noItemsFound}
                        </AppTypography>
                    )}
                    {recommendedAudios?.map((x) => (
                        <RecommendationListItem
                            key={x.nid}
                            audio={x}
                            thumbnail={thumbnails && thumbnails[x.nid]}
                            onClick={() => onRowClick(x, "false")}
                            onBtnClick={(action) => onActionBtnClick(action, x)}
                            redeeming={
                                redeemingContent &&
                                activeRecommendedAudio?.nid === x.nid
                            }
                        />
                    ))}
                </Box>
            </Dialog>
        </Box>
    );
};

const useIndexHooks = (
    props: RecommendationsDialogProps,
    onRecommendationsOpen: () => void,
) => {
    const { isAuto, recommendedAudios } = props;

    const { recommendationState, setGlobalRecommendations } =
        useGlobalRecommendations();
    const { setGlobalBlackRecommendations } = useGlobalBlackRecommendations();

    useEffect(() => {
        if (isAuto) {
            setGlobalRecommendations({
                noOfPopups: recommendedAudios?.length
                    ? recommendationState?.noOfPopups + 1
                    : 3,
            });
        }
    }, [isAuto]);

    useEffect(() => {
        onRecommendationsOpen();
        setGlobalBlackRecommendations({
            firstStart: false,
        });
    }, []);
};

const useHandlers = (
    props: RecommendationsDialogProps,
    recAnalytics: RecommendationAnalytics,
) => {
    const { isAuto, recommendations, push } = props;
    const { recCount, recSource } = recAnalytics;

    const history = useHistory();
    const { linkProvider } = useRouting();

    const {
        transConfirmDialog,
        cart,
        paymentMethod,
        buying,
        redeemingContent,
        purchaseOnRedeemFailDialog,
        loadingCart,
        creditsLoading,
        onBuyClick,
        onRedeemClick,
        onBuyConfirm,
        onCloseDialog: onCloseBuyRedeemDialog,
        onClosePurchaseOnRedeemFailDialog: onClosePurchaseRedeemFail,
    } = useBuyRedeem(props, recAnalytics);

    const [activeRecommendedAudio, setActiveRecommendedAudio] =
        useState<Content>(null);

    const activeRecommendation = useMemo(() => {
        return recommendations?.find(
            (d) => `${d?.nid}` === activeRecommendedAudio?.nid,
        );
    }, [activeRecommendedAudio, recommendations]);

    const onRowClick = (recommendation: Content, isAutoPlay: string) => {
        setActiveRecommendedAudio(recommendation);

        const activeRec = recommendations?.find(
            (d) => `${d?.nid}` === recommendation?.nid,
        );

        const queryStrings = {
            recId: activeRec.rec_id,
            source: recSource,
            displayCount: recCount,
        };

        history.push(
            linkProvider.react
                .audios()
                .detail(
                    recommendation.nid,
                    AudioCategoryTitle.RECOMMENDED,
                    isAutoPlay,
                    queryStrings,
                ),
        );
    };

    const onBackClick = () => {
        if (push) history.push(linkProvider.react.home());
        else if (isAuto) history.goBack();
        else history.push(linkProvider.react.audios().index());
    };

    const onActionBtnClick = (
        action: RecommendationBtn,
        recommendedAudio: Content,
    ) => {
        setActiveRecommendedAudio(recommendedAudio);
        const rec = recommendations?.find(
            (d) => `${d?.nid}` === recommendedAudio?.nid,
        );

        if (action === "Play") onRowClick(recommendedAudio, "true");
        if (action === "Buy") {
            onBuyClick(recommendedAudio, rec);
        }
        if (action === "Redeem") {
            onRedeemClick(recommendedAudio, rec);
        }
    };

    const onTransactionConfirm = () => {
        if (paymentMethod === PaymentMethod.CARD) {
            onBuyConfirm(activeRecommendedAudio, cart, activeRecommendation);
        }
    };

    const onCloseDialog = () => {
        onCloseBuyRedeemDialog(activeRecommendation, activeRecommendedAudio);
    };

    const onClosePurchaseOnRedeemFailDialog = () => {
        onClosePurchaseRedeemFail(activeRecommendation, activeRecommendedAudio);
    };

    return {
        activeRecommendedAudio,
        activeRecommendation,
        cart,
        transConfirmDialog,
        paymentMethod,
        buying,
        purchaseOnRedeemFailDialog,
        loadingCart,
        redeemingContent,
        creditsLoading,
        onRowClick,
        onBackClick,
        onActionBtnClick,
        onCloseDialog,
        onTransactionConfirm,
        onClosePurchaseOnRedeemFailDialog,
    };
};

const useBuyRedeem = (
    props: RecommendationsDialogProps,
    recAnalytics: RecommendationAnalytics,
) => {
    const { onPurchaseAudio } = props;
    const { logRedeemAnalytics, logPurchaseAnalytics } = recAnalytics;

    const { addToCart, cart, loading: loadingCart } = useAddContentToCart();
    const { purchaseByCard, loading: purchasingContent } = usePurchaseByCard();
    const { redeemByCredits, loading: redeemingContent } = useRedeemByCredits();
    const { getAvailableAudioCredits, loading: creditsLoading } =
        useAvailableCredits(true);

    const [transConfirmDialog, setTransConfirmDialog] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
        PaymentMethod.CARD,
    );
    const [purchaseOnRedeemFailDialog, setPurchaseOnRedeemFailDialog] =
        useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const onBuyClick = (
        recommendedAudio: Content,
        recommendation: Recommendations,
    ) => {
        onClosePurchaseOnRedeemFailDialog();
        setPaymentMethod(PaymentMethod.CARD);
        logPurchaseAnalytics("request", recommendation, recommendedAudio);

        addToCart(recommendedAudio?.sku_id, 1).then((res) => {
            if (res?.response?.status) {
                setTransConfirmDialog(true);
            }
        });
    };

    const onRedeemClick = (
        recommendedAudio: Content,
        recommendation: Recommendations,
    ) => {
        setPaymentMethod(PaymentMethod.MY_CREDITS);
        onRedeemConfirm(recommendedAudio, recommendation);
    };

    const onRedeemConfirm = (
        recommendedAudio: Content,
        recommendation: Recommendations,
    ) => {
        const audFirebaseEvent: Partial<FirebaseEventAction> = {
            nId: recommendedAudio?.nid,
            contentTitle: recommendedAudio?.title,
            category: EventCategories.AUDIOS,
        };

        const isForApprentice = +recommendedAudio?.content_price === 0;

        logRedeemAnalytics("request", recommendation, recommendedAudio);

        const fireRedeemFailed = (msg: string) => {
            if (!isForApprentice) onRedeemFailed();
            logRedeemAnalytics("failed", recommendation, recommendedAudio, msg);
        };

        getAvailableAudioCredits().then((val: AppResponse<number>) => {
            const res = val?.response;

            if (res?.status && res.data > +recommendedAudio?.number_of_tokens) {
                redeemByCredits(
                    recommendedAudio?.sku_id,
                    audFirebaseEvent,
                    fireRedeemFailed,
                ).then((val) => {
                    if (val.response?.status) {
                        logRedeemAnalytics(
                            "success",
                            recommendation,
                            recommendedAudio,
                        );
                        onPurchaseAudio(recommendedAudio.nid);
                        onCloseDialog();
                    }
                });
            } else {
                if (!isForApprentice) onRedeemFailed();
                else
                    enqueueSnackbar(messaging.dialogs.recRedeemFailed, {
                        variant: "error",
                    });
            }
        });
    };

    const onBuyConfirm = (
        recommendedAudio: Content,
        cart: AddToCart,
        recommendation: Recommendations,
    ) => {
        const audFirebaseEvent: Partial<FirebaseEventAction> = {
            nId: recommendedAudio?.nid,
            contentTitle: recommendedAudio?.title,
            category: EventCategories.AUDIOS,
        };
        const firePurchaseFailed = (msg: string) => {
            logPurchaseAnalytics(
                "failed",
                recommendation,
                recommendedAudio,
                msg,
            );
        };

        purchaseByCard(
            cart?.cartId,
            1,
            recommendedAudio?.sku_id,
            audFirebaseEvent,
            firePurchaseFailed,
        ).then((val) => {
            if (val.response?.status) {
                logPurchaseAnalytics(
                    "success",
                    recommendation,
                    recommendedAudio,
                );
                onPurchaseAudio(recommendedAudio.nid);
                onCloseDialog();
            }
        });
    };

    const onRedeemFailed = () => {
        onCloseDialog();
        setPurchaseOnRedeemFailDialog(true);
    };

    const onCloseDialog = (
        recommendation?: Recommendations,
        recommendedAudio?: Content,
    ) => {
        const shouldLogAnalytics =
            paymentMethod === PaymentMethod.CARD &&
            recommendation &&
            recommendedAudio;

        if (shouldLogAnalytics) {
            logPurchaseAnalytics(
                "confirm-cancel",
                recommendation,
                recommendedAudio,
            );
        }
        setTransConfirmDialog(false);
    };

    const onClosePurchaseOnRedeemFailDialog = (
        recommendation?: Recommendations,
        recommendedAudio?: Content,
    ) => {
        const shouldLogAnalytics = recommendation && recommendedAudio;

        if (shouldLogAnalytics) {
            logPurchaseAnalytics("canceled", recommendation, recommendedAudio);
        }

        setPurchaseOnRedeemFailDialog(false);
    };

    return {
        paymentMethod,
        transConfirmDialog,
        cart,
        buying: purchasingContent,
        redeemingContent,
        purchaseOnRedeemFailDialog,
        loadingCart,
        creditsLoading,
        onBuyClick,
        onRedeemClick,
        onRedeemConfirm,
        onCloseDialog,
        onBuyConfirm,
        onClosePurchaseOnRedeemFailDialog,
    };
};

const useRecommendationAnalytics = (
    props: RecommendationsDialogProps,
): RecommendationAnalytics => {
    const { isAuto, recommendations, recommendedAudios } = props;

    const { logFirebaseEvent } = useFirebaseLogger();
    const { recommendationState, setGlobalRecommendations } =
        useGlobalRecommendations();

    const recCount = recommendedAudios ? recommendedAudios?.length : 0;
    const recSource: RecommendationSource = isAuto ? "Auto Pop Up" : "Manual";
    const recIds = recommendations?.map((d) => d.rec_id);
    const recIdsStr = restrictRecIdCountAndString(recIds);
    const autoOpenedCount = isAuto
        ? recommendationState?.noOfPopups + 1
        : recommendationState?.noOfPopups;
    const batchIds = recommendations?.map((d) => d.batch_id);

    const onRecommendationsOpen = () => {
        if (recommendations) {
            const toLog: FirebaseEventAction = {
                category: EventCategories.RECOMMENDATIONS,
                action: EventActions.OPENED,
                displayCount: recCount,
                source: recSource,
                recIds: recIdsStr,
            };
            if (isAuto) {
                toLog.autoTriggerCount = autoOpenedCount;
            }
            logFirebaseEvent(EventNames.RECOMMENDATIONS_OPEN, toLog);
            onNewBatch();
        }
    };

    const onNewBatch = () => {
        const localBatch = recommendationState?.batch;
        if (localBatch && batchIds?.length) {
            const batchChanged = localBatch?.batchId !== +batchIds[0];

            if (batchChanged) {
                setGlobalRecommendations({
                    batch: {
                        ...localBatch,
                        batchId: +batchIds[0],
                    },
                    lastFetched: moment().unix(),
                    noOfPopups: 1,
                });
            }
        }
    };

    const getCommonEventActions = (
        recommendation: Recommendations,
        recommendedAudio: Content,
    ): Partial<FirebaseEventAction> => {
        return {
            category: EventCategories.RECOMMENDATIONS,
            recId: `${recommendation.rec_id}`,
            nId: `${recommendation.nid}`,
            skuId: recommendation.sku,
            contentTitle: recommendedAudio.title,
            displayCount: recCount,
            source: recSource,
        };
    };

    const logRedeemAnalytics = (
        type: "request" | "failed" | "success",
        recommendation: Recommendations,
        recommendedAudio: Content,
        failureCause?: string,
    ) => {
        if (recommendation && recommendedAudio) {
            const commonEventActions = getCommonEventActions(
                recommendation,
                recommendedAudio,
            );

            const actionAndEvent = recRedeemAnalyticsActionAndEvent(type);

            const toLog: FirebaseEventAction =
                type === "failed"
                    ? {
                          ...commonEventActions,
                          action: actionAndEvent.action,
                          cause: failureCause,
                      }
                    : {
                          ...commonEventActions,
                          action: actionAndEvent.action,
                      };

            logFirebaseEvent(actionAndEvent.name, toLog);
        }
    };

    const logPurchaseAnalytics = (
        type: "request" | "failed" | "success" | "canceled" | "confirm-cancel",
        recommendation: Recommendations,
        recommendedAudio: Content,
        failureCause?: string,
    ) => {
        if (recommendation && recommendedAudio) {
            const commonEventActions = getCommonEventActions(
                recommendation,
                recommendedAudio,
            );

            const actionAndEvent = recPurchaseAnalyticsActionAndEvent(type);

            const toLog: FirebaseEventAction =
                type === "failed"
                    ? {
                          ...commonEventActions,
                          action: actionAndEvent.action,
                          cause: failureCause,
                      }
                    : {
                          ...commonEventActions,
                          action: actionAndEvent.action,
                      };

            logFirebaseEvent(actionAndEvent.name, toLog);
        }
    };

    return {
        recCount,
        recSource,
        onRecommendationsOpen,
        logRedeemAnalytics,
        logPurchaseAnalytics,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
        },
        dialog: {
            "-webkit-overflow-scrolling": "touch",
        },
        dialogWrapper: {
            minHeight: "100vh",
            backgroundColor: theme.palette.background.default,
        },
        emptyList: {
            marginTop: theme.spacing(3),
            color: theme.palette.grey[500],
        },
    }),
);
