import { config } from "JS/Config";
import { messaging } from "JS/Helpers/UserMessaging";
import {
    Content,
    ContentMedia,
    ContentWithMedia,
    Gifts,
    Playlist,
    PlaylistAudio,
} from "JS/Models";
import {
    ResumePlaylist,
    migratePlaylistData,
    setGlobalPlaylist,
    updatePlaylistResume,
} from "JS/Redux/Playlist";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { PlaylistService } from "JS/Services/Playlist";
import { useSnackbar } from "notistack";
import React, { useMemo } from "react";
import { useCallback, useEffect, useState } from "react";
import { useGlobalAudios } from "./Audio";

const service = new PlaylistService();

export const usePlaylists = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{ error: string }>();
    const [isEmpty, setEmpty] = useState<{ empty: boolean }>();
    const dispatch = useAppDispatch();

    const { userPlaylists, playlistResumeMap, allPlaylists } =
        useGlobalPlaylists();

    const fetch = useCallback(() => {
        setLoading(userPlaylists.length === 0);
        service
            .getPlaylists()
            .then((r) => {
                const response = r.response;
                if (response?.status) {
                    const playlists = response.data;
                    setEmpty({ empty: playlists.length === 0 });
                    const toSave = playlists.map((p) => {
                        const existing = userPlaylists?.find(
                            (e) => e.playlist_id === p.playlist_id,
                        );
                        if (existing)
                            return {
                                ...p,
                                audios: existing?.audios,
                                count: existing?.count,
                                showCount: existing?.showCount,
                            };
                        else return p;
                    });
                    dispatch(
                        setGlobalPlaylist({
                            playlistsMap: {
                                ...allPlaylists,
                                [config?.user?.memberId]: toSave,
                            },
                            playlistResumeMap,
                        }),
                    );
                } else {
                    onError(response?.message);
                }
            })
            .catch((e) => {
                onError(e.toString());
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (!skip) fetch();
    }, [skip]);

    function onError(message: string) {
        if (userPlaylists?.length === 0) setError({ error: message });
    }
    return {
        loading,
        error,
        isEmpty,
        fetch,
    };
};

export const useCreatePlaylist = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{ error: string }>();
    const [success, setSuccess] = useState<{
        success: string;
        playlist_id: string;
    }>();
    const dispatch = useAppDispatch();
    const { userPlaylists, playlistResumeMap, allPlaylists } =
        useGlobalPlaylists();

    const create = (title: string) => {
        setLoading(true);
        service
            .createPlaylist(title)
            .then((r) => {
                if (r.response.status) {
                    const toSave = userPlaylists?.map((p) => p);
                    toSave.push({
                        playlist_id: r.response.data.playlist_id,
                        playlist_name: title,
                        sort_order: "0",
                        count: "0",
                        showCount: false,
                    });

                    dispatch(
                        setGlobalPlaylist({
                            playlistsMap: {
                                ...allPlaylists,
                                [config?.user?.memberId]: toSave,
                            },
                            playlistResumeMap,
                        }),
                    );
                    setSuccess({
                        success: r.response.data.message,
                        playlist_id: r.response.data.playlist_id,
                    });
                } else setError({ error: r.response.message });
            })
            .catch((e) => setError({ error: e.toString() }))
            .finally(() => setLoading(false));
    };

    return {
        loading,
        error,
        success,
        create,
    };
};

export const useDeletePlaylist = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{ error: string }>();
    const [success, setSuccess] = useState<{ success: string }>();
    const dispatch = useAppDispatch();
    const { userPlaylists, playlistResumeMap, allPlaylists } =
        useGlobalPlaylists();

    const deletePlaylist = (id: string) => {
        setLoading(true);
        service
            .deletePlaylist(id)
            .then((r) => {
                if (r.response.status) {
                    setSuccess({ success: "Playlist deleted successfully" });
                    const toSave = userPlaylists
                        .map((p) => p)
                        .filter((p) => p.playlist_id !== id);
                    dispatch(
                        setGlobalPlaylist({
                            playlistsMap: {
                                ...allPlaylists,
                                [config?.user?.memberId]: toSave,
                            },
                            playlistResumeMap,
                        }),
                    );
                } else setError({ error: r.response.message });
            })
            .catch((e) => setError({ error: e.toString() }))
            .finally(() => setLoading(false));
    };

    return {
        loading,
        error,
        success,
        deletePlaylist,
    };
};

export const useRenamePlaylist = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{ error: string }>();
    const [success, setSuccess] = useState<{ success: string }>();
    const dispatch = useAppDispatch();
    const { userPlaylists, playlistResumeMap, allPlaylists } =
        useGlobalPlaylists();

    const rename = (title: string, id: string) => {
        setLoading(true);
        service
            .renamePlaylist(id, title)
            .then((r) => {
                if (r.response.status) {
                    setSuccess({ success: "Playlist renamed successfully" });
                    const toSave = userPlaylists?.map((p) => {
                        if (p.playlist_id !== id) return p;
                        else
                            return {
                                ...p,
                                playlist_name: title,
                            };
                    });
                    dispatch(
                        setGlobalPlaylist({
                            playlistsMap: {
                                ...allPlaylists,
                                [config?.user?.memberId]: toSave,
                            },
                            playlistResumeMap,
                        }),
                    );
                } else setError({ error: r.response.message });
            })
            .catch((e) => setError({ error: e.toString() }))
            .finally(() => setLoading(false));
    };

    return {
        loading,
        error,
        success,
        rename,
    };
};

export const useSortPlaylists = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{ error: string }>();
    const [success, setSuccess] = useState<{ success: string }>();
    const dispatch = useAppDispatch();
    const { playlistResumeMap, allPlaylists } = useGlobalPlaylists();

    const sort = (playlists: Playlist[]) => {
        setLoading(true);
        service
            .sortPlaylists(
                playlists
                    .map((p) => p.playlist_id)
                    .reduceRight((a, b) => `${b},${a}`),
            )
            .then((r) => {
                if (r.response.status) {
                    setSuccess({
                        success: "Playlists position updated successfully",
                    });
                    const toSave: Playlist[] = [];
                    playlists.forEach((p, i) => {
                        toSave.push({
                            ...p,
                            sort_order: i.toString(),
                        });
                    });
                    dispatch(
                        setGlobalPlaylist({
                            playlistsMap: {
                                ...allPlaylists,
                                [config?.user?.memberId]: toSave,
                            },
                            playlistResumeMap,
                        }),
                    );
                } else setError({ error: r.response.message });
            })
            .catch((e) => {
                setError({ error: e.toString() });
            })
            .finally(() => setLoading(false));
    };

    return {
        loading,
        error,
        success,
        sort,
    };
};

export const usePlaylistAudios = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    const { userPlaylists, playlistResumeMap, allPlaylists } =
        useGlobalPlaylists();
    const dispatch = useAppDispatch();
    const { setPlaylistAudioDetail } = useGlobalAudios();

    const getAudios = (id: string) => {
        const audiosInStore = userPlaylists.find(
            (p) => p.playlist_id === id,
        )?.audios;
        setLoading(audiosInStore && audiosInStore.length > 0);
        service
            .getPlaylistAudios(id)
            .then((r) => {
                if (r.response.status) {
                    const responseAudios = r.response.data;
                    const playlistsToSave = userPlaylists.map((p) => {
                        if (p.playlist_id === id)
                            return {
                                ...p,
                                count: responseAudios.length.toString(),
                                showCount: responseAudios.length > 0,
                                audios: responseAudios.map((a) => {
                                    return {
                                        ...a,
                                        media: null,
                                        description: null,
                                    };
                                }),
                            };
                        else return p;
                    });

                    dispatch(
                        setGlobalPlaylist({
                            playlistsMap: {
                                ...allPlaylists,
                                [config?.user?.memberId]: playlistsToSave,
                            },
                            playlistResumeMap,
                        }),
                    );
                    setPlaylistAudioDetail(responseAudios);
                    if (responseAudios.length === 0)
                        setError("There are no audios in this playlist.");
                } else setError(r.response.message);
            })
            .catch((e) => {
                setError(e.toString());
            })
            .finally(() => setLoading(false));
    };

    return {
        loading,
        error,
        getAudios,
    };
};

export const useRemoveAudio = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{ error: string }>();
    const [success, setSuccess] = useState<{ success: string }>();
    const { userPlaylists, playlistResumeMap, allPlaylists } =
        useGlobalPlaylists();
    const dispatch = useAppDispatch();

    const removeAudio = (playlistId: string, audioNid: string) => {
        setLoading(true);
        return service
            .removeAudioFromPlaylist(playlistId, audioNid)
            .then((r) => {
                if (r.response.status) {
                    setSuccess({ success: "Audio removed from playlist." });
                    const toSave = userPlaylists?.map((p) => {
                        if (p.playlist_id === playlistId) {
                            const audios = p.audios.filter(
                                (a) => a.nid !== audioNid,
                            );
                            return {
                                ...p,
                                showCount: audios.length > 0,
                                count: audios.length.toString(),
                                audios: audios,
                            };
                        } else return p;
                    });

                    dispatch(
                        setGlobalPlaylist({
                            playlistsMap: {
                                ...allPlaylists,
                                [config?.user?.memberId]: toSave,
                            },
                            playlistResumeMap,
                        }),
                    );

                    return audioNid;
                } else {
                    setError({ error: r.response.message });
                    return null as string;
                }
            })
            .catch((e) => {
                setError({ error: e.toString() });
                return null as string;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        loading,
        error,
        success,
        removeAudio,
    };
};

export const useAddToPlaylist = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{ error: string }>();
    const [success, setSuccess] = useState<{
        playlistId: string;
        success: string;
    }>();
    const { userPlaylists, playlistResumeMap, allPlaylists } =
        useGlobalPlaylists();
    const dispatch = useAppDispatch();

    const addAudio = (
        playlistId: string,
        audioToAdd: ContentWithMedia | Gifts,
    ) => {
        setLoading(true);
        service
            .addAudioToPlaylist(playlistId, audioToAdd.nid)
            .then((r) => {
                if (r.response.status) {
                    setSuccess({
                        playlistId: playlistId,
                        success: "Audio added to playlist.",
                    });
                    const toSave = userPlaylists?.map((p) => {
                        if (p.playlist_id === playlistId) {
                            const newAudios: PlaylistAudio[] = p.audios
                                ? [...p.audios]
                                : [];
                            const audio: PlaylistAudio = {
                                sku_id: audioToAdd.sku_id,
                                media: audioToAdd.media,
                                description: audioToAdd.description,
                                nid: audioToAdd.nid,
                                sort_order: newAudios.length.toString(),
                            };
                            newAudios.push(audio);
                            return {
                                ...p,
                                showCount: p.showCount ? p.showCount : false,
                                count: newAudios.length.toString(),
                                audios: newAudios,
                            };
                        } else return p;
                    });
                    dispatch(
                        setGlobalPlaylist({
                            playlistsMap: {
                                ...allPlaylists,
                                [config?.user?.memberId]: toSave,
                            },
                            playlistResumeMap,
                        }),
                    );
                } else setError({ error: r.response.message });
            })
            .catch((e) => setError({ error: e.toString() }))
            .finally(() => setLoading(false));
    };

    return {
        loading,
        error,
        success,
        addAudio,
    };
};

export const useSortAudios = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{ error: string }>();
    const [success, setSuccess] = useState<{ success: string }>();
    const { userPlaylists, playlistResumeMap, allPlaylists } =
        useGlobalPlaylists();
    const dispatch = useAppDispatch();

    const sortAudios = (playlistId: string, audios: { sku_id: string }[]) => {
        setLoading(true);
        service
            .sortAudiosFromPlaylist(
                playlistId,
                audios.map((a) => a.sku_id).reduceRight((a, b) => `${b},${a}`),
            )
            .then((r) => {
                if (r.response.status) {
                    setSuccess({
                        success:
                            "Playlist items position updated successfully.",
                    });
                    const toSave = userPlaylists?.map((p) => {
                        if (p.playlist_id === playlistId) {
                            let newAudios: PlaylistAudio[] = [];
                            audios.forEach((a, i) =>
                                newAudios.push({
                                    ...p.audios.find(
                                        (pa) => pa.sku_id === a.sku_id,
                                    ),
                                    sort_order: i.toString(),
                                }),
                            );
                            return {
                                ...p,
                                audios: newAudios,
                            };
                        } else return p;
                    });
                    dispatch(
                        setGlobalPlaylist({
                            playlistsMap: {
                                ...allPlaylists,
                                [config?.user?.memberId]: toSave,
                            },
                            playlistResumeMap,
                        }),
                    );
                } else setError({ error: r.response.message });
            })
            .catch((e) => setError({ error: e.toString() }))
            .finally(() => setLoading(false));
    };

    return {
        loading,
        error,
        success,
        sortAudios,
    };
};

export const useGlobalPlaylists = () => {
    const dispatch = useAppDispatch();

    const allData = useAppSelector((state) => state.playlist);

    const resumeMap = useMemo(
        () =>
            allData.playlistResumeMap ? { ...allData.playlistResumeMap } : {},
        [allData],
    );
    const resumeList = useMemo(
        () =>
            resumeMap[config.user.memberId]
                ? [...resumeMap[config.user.memberId]]
                : [],
        [resumeMap],
    );

    const setGlobalPlaylists = (playlists: Playlist[]) => {
        dispatch(
            setGlobalPlaylist({
                ...allData,
                playlistsMap: {
                    ...allData.playlistsMap,
                    [config?.user?.memberId]: playlists,
                },
            }),
        );
    };

    const userPlaylists = useMemo(
        () =>
            allData?.playlistsMap &&
            allData?.playlistsMap[`${config?.user?.memberId}`]
                ? allData?.playlistsMap[`${config?.user?.memberId}`]
                : [],
        [allData],
    );

    const allPlaylists = useMemo(
        () => (allData?.playlistsMap ? allData?.playlistsMap : {}),
        [allData],
    );

    return {
        userPlaylists,
        allPlaylists,
        playlistResumeMap: resumeMap,
        userPlaylistResume: resumeList,
        setGlobalPlaylists,
        savePlaylistResumeState: (state: ResumePlaylist) => {
            const existing = resumeList.find((r) => r.id === state.id);
            const newResumeList = resumeList;
            if (existing)
                newResumeList[newResumeList.indexOf(existing)] = state;
            else newResumeList.push(state);
            const newResumeMap = resumeMap;
            newResumeMap[config.user.memberId] = newResumeList;
            dispatch(
                setGlobalPlaylist({
                    ...allData,
                    playlistResumeMap: newResumeMap,
                }),
            );
        },
        playlistResumeState: (
            playlist: Playlist,
            audios?: (Content | Gifts)[],
        ) => {
            const resumeData = resumeList.find(
                (r) => r.id === playlist.playlist_id,
            );
            if (resumeData) {
                const nids = !!audios
                    ? audios.map((a) => a.nid)
                    : playlist.audios.map((a) => a.nid);
                const existingNid = nids.find(
                    (nid) => nid === resumeData.resumeAudioNid,
                );
                return {
                    resumeAudioIndex: !!existingNid
                        ? nids.indexOf(existingNid)
                        : "0",
                    resumeBundleIndex: !!existingNid
                        ? resumeData.resumeBundleIndex
                        : "0",
                };
            }
            return undefined;
        },
        migratePlaylistData: () => dispatch(migratePlaylistData()),
        syncPlaylistData: (state: ResumePlaylist[]) => {
            dispatch(
                updatePlaylistResume({
                    ...resumeMap,
                    [config.user.memberId]: state,
                }),
            );
        },
    };
};

export const usePlaylistCommonHandlers = (audio: Gifts | ContentWithMedia) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isCreatePlaylist, setCreatePlaylist] = useState(false);

    const { userPlaylists: playlistsInStore } = useGlobalPlaylists();
    const {
        loading: addToPlaylistLoading,
        error: addToPlaylistError,
        success: addToPlaylistSuccess,
        addAudio,
    } = useAddToPlaylist();
    const {
        loading: playlistsLoading,
        error: playlistsError,
        isEmpty: playlistsEmpty,
        fetch: fetchPlaylists,
    } = usePlaylists(true);
    const {
        loading: createLoading,
        error: createError,
        success: createSuccess,
        create: createPlaylist,
    } = useCreatePlaylist();

    const [anchorPlaylist, setAnchorPlaylist] =
        React.useState<null | HTMLElement>(null);
    const openPlaylistSelection = Boolean(anchorPlaylist);

    useEffect(() => {
        if (openPlaylistSelection && playlistsInStore.length === 0)
            fetchPlaylists();
    }, [openPlaylistSelection, playlistsInStore, fetchPlaylists]);

    useEffect(() => {
        if (playlistsError) {
            enqueueSnackbar(playlistsError.error, {
                variant: "error",
            });
        }
    }, [playlistsError, enqueueSnackbar]);

    useEffect(() => {
        if (addToPlaylistError) {
            enqueueSnackbar(addToPlaylistError.error, {
                variant: "error",
            });
        }
    }, [addToPlaylistError, enqueueSnackbar]);

    useEffect(() => {
        const shouldCreate = playlistsEmpty && playlistsEmpty.empty;
        setCreatePlaylist(shouldCreate);
        if (shouldCreate) setAnchorPlaylist(null);
    }, [playlistsEmpty, setCreatePlaylist]);

    useEffect(() => {
        if (createError)
            enqueueSnackbar(createError.error, {
                variant: "error",
            });
    }, [createError, enqueueSnackbar]);

    useEffect(() => {
        if (createSuccess && createSuccess.success && audio)
            addAudio(createSuccess.playlist_id, audio);
    }, [createSuccess, audio]);

    const handlePlaylistClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        setAnchorPlaylist(e.currentTarget);
    };

    const createClick = (input: string) => {
        setCreatePlaylist(false);
        if (input && input.length <= 45) {
            createPlaylist(input);
        } else {
            enqueueSnackbar(
                !input || input.length === 0
                    ? messaging?.playlist?.emptyInput
                    : messaging?.playlist?.characterInput,
                {
                    variant: "warning",
                },
            );
        }
    };

    return {
        isCreatePlaylist,
        addToPlaylistLoading,
        addToPlaylistSuccess,
        playlistsLoading,
        createLoading,
        openPlaylistSelection,
        playlistsInStore,
        handlePlaylistClick,
        createClick,
        addAudio,
        setAnchorPlaylist,
        setCreatePlaylist,
    };
};

export const usePlaylistResume = (
    id: string,
    audioNid: string,
    bundleIndex?: number,
) => {
    const { savePlaylistResumeState } = useGlobalPlaylists();

    useEffect(() => {
        if (id) {
            savePlaylistResumeState({
                id,
                resumeAudioNid: audioNid,
                resumeBundleIndex: bundleIndex,
            });
        }
    }, [id, audioNid, bundleIndex]);

    return {
        savePlaylistResumeState,
    };
};

export const useCreatePlaylistWithContents = () => {
    const { enqueueSnackbar } = useSnackbar();
    type errorType = {
        error: string;
    };

    type successType = {
        status: boolean;
        message: string;
        key: number;
    };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<errorType>();
    const [success, setSuccess] = useState<successType>();

    const createPlaylistWithContents = async (
        title: string,
        contentIds: string[],
    ) => {
        setLoading(true);
        return service
            .createPlaylistWithContents(title, contentIds)
            .then((response) => {
                if (response.status) {
                    enqueueSnackbar(messaging.playlist.created, {
                        variant: "success",
                    });
                    setSuccess({
                        message: response.message,
                        status: response.status,
                        key: response.key,
                    });
                    return response;
                } else {
                    enqueueSnackbar(
                        response?.message
                            ? response?.message
                            : messaging.playlist.error,
                        {
                            variant: "warning",
                        },
                    );
                    setSuccess({
                        message: response.message,
                        status: response.status,
                        key: response.key,
                    });
                    return response;
                }
            })
            .catch((e) => {
                enqueueSnackbar(messaging.playlist.error, {
                    variant: "error",
                });
                setError({ error: e.toString() });
                return e;
            })
            .finally(() => setLoading(false));
    };

    return {
        loading,
        error,
        success,
        createPlaylistWithContents,
    };
};
