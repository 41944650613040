import { bulkDeleteAudiosByIds, getAudioById } from "JS/Database/Audio";
import { AudioDexie, VideoDexie } from "JS/Database/Dexie";
import { bulkDeleteVideosByIds, getVideoById } from "JS/Database/Video";
import { getDownloadedAudios, getDownloadedPartsNids } from "JS/Helpers";
import { appConstants } from "JS/Helpers/Contants";
import {
    addToDownloadQueue,
    getDownloadQueueLength,
    DownloadStatus,
    getDownloadingStatus,
} from "JS/Helpers/ContentDownloadHelper";
import { Content, ContentWithMedia, Gifts } from "JS/Models";
import { AddToDownloadOptions } from "JS/Models/Common";
import { useCallback, useState } from "react";

export const useGetDownloadedAudio = () => {
    const getDownloadedAudioById = useCallback((id: string) => {
        return getAudioById(id)
            .then((res) => {
                return res;
            })
            .catch(() => {
                return null as AudioDexie;
            });
    }, []);

    return {
        getDownloadedAudioById,
    };
};

export const useDeleteAudios = () => {
    const [deleting, setDeleting] = useState(false);

    const deleteAudiosById = (
        ids: string[],
        onSuccess?: () => void,
        onFailed?: () => void,
    ) => {
        setDeleting(true);
        bulkDeleteAudiosByIds(ids)
            .then(() => {
                onSuccess && onSuccess();
            })
            .catch(() => {
                onFailed && onFailed();
            })
            .finally(() => {
                setDeleting(false);
            });
    };

    return {
        deleteAudiosById,
        deleting,
    };
};

export const useDeleteVideos = () => {
    const [deleting, setDeleting] = useState(false);

    const deleteVideosById = (
        ids: string[],
        onSuccess?: () => void,
        onFailed?: () => void,
    ) => {
        setDeleting(true);
        bulkDeleteVideosByIds(ids)
            .then(() => {
                onSuccess && onSuccess();
            })
            .catch(() => {
                onFailed && onFailed();
            })
            .finally(() => {
                setDeleting(false);
            });
    };

    return {
        deleteVideosById,
        deleting,
    };
};

export const useDownloadAudios = () => {
    const [limitReached, setLimitReached] = useState<{
        count: number;
    }>();
    const [loading, setLoading] = useState(false);

    const downloadAudios = async (audios: (ContentWithMedia | Gifts)[]) => {
        setLoading(true);
        const downloadingQueueLimit =
            appConstants.downloadingQueue.downloadingQueueLimit;
        const availableLength = getDownloadQueueLength();
        if (availableLength < downloadingQueueLimit) {
            for (const audio of audios) {
                const currentLength = getDownloadQueueLength();
                const audioFiles = +audio?.no_of_files;
                if (
                    currentLength === downloadingQueueLimit ||
                    (audioFiles > 1 &&
                        currentLength +
                            audioFiles -
                            (await getDownloadedPartsNids(audio.nid)).length >
                            downloadingQueueLimit)
                ) {
                    setLimitReached({
                        count:
                            audios.length -
                            (await getDownloadedAudios(audios)).length,
                    });
                    break;
                } else {
                    const type =
                        audioFiles > 1
                            ? audio.isReceived
                                ? "giftAudioBundle"
                                : "audioBundle"
                            : audio.isReceived
                            ? "giftAudio"
                            : "audio";

                    const {
                        media_url_prefix,
                        media_url_postfix,
                        media_file_name,
                    } = "media" in audio ? audio.media[0] : audio;

                    const options: AddToDownloadOptions = {
                        name: audio.title,
                        type: type,
                        nid: audio.nid,
                        description: audio.description,
                        release_date: audio.release_date,
                        media_url_prefix,
                        media_url_postfix,
                        media_file_name,
                        skuId: audio.sku_id,
                        media: audio.media,
                    };
                    await addToDownloadQueue(options);
                    const downloadingStatus = await getDownloadingStatus(
                        `${media_url_postfix}/${media_file_name}`,
                        media_url_prefix,
                    );
                    if (downloadingStatus === DownloadStatus.BLOCK) break;
                }
            }
        } else {
            setLimitReached({ count: 0 });
        }
        setTimeout(
            () => {
                setLoading(false);
            },
            audios?.length < 20 ? 1000 : 0,
        );
    };

    return {
        loading,
        limitReached,
        setLimitReached,
        downloadAudios,
    };
};

export const useGetDownloadedVideo = () => {
    const getDownloadedVideoById = useCallback((id: string) => {
        return getVideoById(id)
            .then((res) => {
                return res;
            })
            .catch(() => {
                return null as VideoDexie;
            });
    }, []);

    return {
        getDownloadedVideoById,
    };
};
