import { config } from "JS/Config";
import { Content, ContentDetails, ContentResponse } from "JS/Models";
import { AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";

export class AudioService extends BaseService {
    async getAudiosIndex() {
        const formData = new FormData();

        formData.append("access_token", `${config?.accessToken}`);

        return (
            await this.doXHR<AppResponse<Content[]>>({
                url: this.routes.server.api.audios.getAudiosIndex(),
                method: "POST",
                data: formData,
            })
        ).data;
    }

    async getAudios() {
        const formData = new FormData();
        formData.append("access_token", `${config?.accessToken}`);
        return (
            await this.doXHR<AppResponse<ContentResponse>>({
                data: formData,
                url: this.routes.server.api.audios.getAudios(),
                method: "POST",
            })
        ).data;
    }

    async getAudioDetails(nid: string) {
        const formData = new FormData();
        formData.append("access_token", `${config?.accessToken}`);
        formData.append("nId", nid);
        return (
            await this.doXHR<AppResponse<ContentDetails>>({
                data: formData,
                url: this.routes.server.api.audios.getAudioDetail(),
                method: "POST",
            })
        ).data;
    }
}
