import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    Dialog,
    DialogProps,
    InternalStandardProps,
    Slide,
    Theme,
    Grid,
    IconButton,
    Box,
    DialogContent,
    ListItem,
    ListItemButton,
    Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { createStyles, makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { Content, Gifts } from "JS/Models";
import { PaginationInfo, paginateData } from "JS/Types/Pagination";
import { AppTypography } from "JS/React/Components/AppTypography";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { getPublicUrl } from "JS/Helpers";
import { messaging } from "JS/Helpers/UserMessaging";
import { useGlobalAttributes } from "JS/React/Hooks/Attributes";
import { AppStandardTextFieldWithRef } from "JS/React/Components/TextFields/AppStandardTextField";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import PlaylistContentListItem from "./Components/PlaylistContentListItem";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const SpeakerPlaylistDialog = (props: SpeakerPlaylistDialogProps) => {
    const {
        audios,
        handleClose,
        handleCreatePlaylist,
        onToggleSelectItem,
        selectedContentNids,
        sortType,
        playlistName,
        setPlaylistName,
        createPlaylistLoading = false,
        open,
        ...rest
    } = props;
    const classes = useStyles(props);
    const { className } = props;

    const inputRef = useRef(null);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            const inputElement = document.activeElement as HTMLInputElement;
            if (inputElement) {
                inputElement.blur();
            }
        }
    };

    const { finalData, thumbnails, paginationInfo, onLastContentHit } =
        useSpeakerPlaylist(audios);

    const observer = useRef<IntersectionObserver>();

    const lastElementRef = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    onLastContentHit();
                }
            });
            if (node) observer.current.observe(node);
        },
        [onLastContentHit],
    );

    return (
        <Dialog
            fullWidth
            role="dialog"
            className={classes.root}
            open={open}
            TransitionComponent={Transition}
            {...rest}
        >
            <Box className={clsx(className, classes.dialogWarpper)}>
                <DialogContent className={classes.mainContent}>
                    <Grid container className={classes.topContent}>
                        <Grid
                            item
                            xs={12}
                            className={classes.contentHeaderItem}
                        >
                            <Box
                                display={"flex"}
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                            >
                                <AppTypography
                                    variant="h6"
                                    aria-label={"Create Playlist"}
                                >
                                    Create Playlist
                                </AppTypography>
                            </Box>
                            <IconButton onClick={handleClose}>
                                <FontAwesomeIcon
                                    className={classes.filterIcon}
                                    icon={["fas", "xmark"]}
                                />
                            </IconButton>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{ marginTop: "5vh", marginBottom: "2%" }}
                        >
                            <AppStandardTextFieldWithRef
                                ref={inputRef}
                                fullWidth
                                placeholder="Playlist Name"
                                value={playlistName}
                                onChange={(e) =>
                                    setPlaylistName(e.target.value)
                                }
                                onKeyDown={handleKeyPress}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            className={classes.warpperChkBoxList}
                        >
                            {finalData && finalData.length > 0 ? (
                                <>
                                    {finalData.map((filteredAudio, idx) => {
                                        return (
                                            <PlaylistContentListItem
                                                key={`${filteredAudio.nid}-${idx}`}
                                                nid={filteredAudio.nid}
                                                skuId={filteredAudio.sku_id}
                                                description={
                                                    filteredAudio.description
                                                }
                                                imageUrl={
                                                    thumbnails &&
                                                    thumbnails[
                                                        filteredAudio.nid
                                                    ]
                                                }
                                                title={filteredAudio.title}
                                                acquiredDate={
                                                    filteredAudio.acquired_date
                                                }
                                                releaseDate={
                                                    filteredAudio.release_date
                                                }
                                                played={filteredAudio.played}
                                                sortType={sortType}
                                                handleToogleCheckBox={
                                                    onToggleSelectItem
                                                }
                                                isCheckedItem={selectedContentNids?.includes(
                                                    filteredAudio.nid,
                                                )}
                                            />
                                        );
                                    })}
                                    {paginationInfo &&
                                        paginationInfo.perPage <
                                            paginationInfo.total && (
                                            <div
                                                className={classes.navLoadMore}
                                                ref={lastElementRef}
                                            >
                                                <Typography variant="body1">
                                                    {
                                                        messaging.pagination
                                                            .loadMore
                                                    }
                                                </Typography>
                                            </div>
                                        )}
                                </>
                            ) : (
                                <AppTypography
                                    className={classes.emptyList}
                                    align="center"
                                    variant="h6"
                                >
                                    {messaging.common.noItemsFound}
                                </AppTypography>
                            )}
                        </Grid>
                    </Grid>
                    {selectedContentNids?.length > 0 && (
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                className={classes.btnCreateWrapper}
                            >
                                <ListItem key="btnOk" disablePadding>
                                    <ListItemButton
                                        className={classes.btnCreate}
                                        onClick={
                                            !createPlaylistLoading
                                                ? handleCreatePlaylist
                                                : () => {}
                                        }
                                    >
                                        {createPlaylistLoading ? (
                                            <AppCircularProgress
                                                notPrimary={true}
                                                size={"small"}
                                            />
                                        ) : (
                                            "Create"
                                        )}
                                    </ListItemButton>
                                </ListItem>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
            </Box>
        </Dialog>
    );
};

const useSpeakerPlaylist = (audios: (Content | Gifts)[]) => {
    const { verifyAwsKeys } = useVerifyAwsKeys();

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        currentPage: 1,
        perPage: 25,
        total: 0,
    });

    const { audioSelectedSpeakers } = useGlobalAttributes();

    useEffect(() => {
        setPaginationInfo({
            ...paginationInfo,
            total: audios
                ? audioSelectedSpeakers?.length === 0 || audios.length < 400
                    ? audios.length
                    : 400
                : 0,
        });
    }, [audios, audioSelectedSpeakers]);

    const finalData = useMemo(() => {
        return paginateData(audios, paginationInfo).data;
    }, [paginationInfo, audios]);
    const [thumbnails, setThumbnails] = useState<{
        [key: string]: string;
    }>(null);

    useEffect(() => {
        if (finalData) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [finalData]);

    const createImageUrl = async (audio: Content | Gifts) => {
        const url = await getPublicUrl(
            audio.image_url_prefix,
            audio.image_url_postfix,
            audio.image_file_name,
        );

        return {
            id: audio.nid,
            url: url,
        };
    };

    const fetchThumbnails = async () => {
        let promises: Promise<{
            id: string;
            url: string;
        }>[] = [];

        finalData.forEach((d) => {
            if (!thumbnails || (thumbnails && !thumbnails[d.nid])) {
                const promise = createImageUrl(d as Content);
                promises.push(promise);
            }
        });

        const thumbs = await Promise.all(promises);

        let toRet: {
            [key: string]: string;
        } = {};

        thumbs.forEach((t) => {
            toRet = {
                ...toRet,
                [t.id]: t.url,
            };
        });

        setThumbnails((prev) => ({
            ...prev,
            ...toRet,
        }));
    };

    const onLastContentHit = () => {
        setPaginationInfo({
            ...paginationInfo,
            perPage: paginationInfo.perPage + 25,
        });
    };

    return {
        searchQuery,
        setSearchQuery,
        finalData,
        thumbnails,
        paginationInfo,
        onLastContentHit,
    };
};

export interface SpeakerPlaylistDialogProps
    extends InternalStandardProps<DialogProps> {
    audios: (Content | Gifts)[];
    handleClose: () => void;
    handleCreatePlaylist: () => void;
    onToggleSelectItem: (nid: string, checked: boolean) => void;
    selectedContentNids: string[];
    sortType?: string;
    setPlaylistName: React.Dispatch<React.SetStateAction<string>>;
    playlistName: string;
    createPlaylistLoading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            margin: theme.spacing(1),
        },
        button: {
            width: "100%",
        },
        filterDialogTitle: {
            color: theme.palette.common.black,
        },
        filterIcon: {
            fontSize: "22px",
            cursor: "pointer",
            color: theme.palette.grey[600],
        },
        root: {
            boxShadow: `0px -8px 10px ${theme.palette.grey[200]}`,
            minWidth: "100%",
            minHeight: `calc(95vh - (${theme.footer.height} + ${theme.header.height}))`,
            border: `1px solid ${theme.palette.grey[200]}`,
        },
        dialogWarpper: {
            backgroundColor: `${theme.palette.background.default}`,
            color: `${theme.palette.common.black}`,
        },
        warpperChkBoxList: {
            overflow: "auto",
            height: `calc(70vh - (${theme.footer.height} + ${theme.header.height}))`,
            backgroundColor: theme.palette.background.default,
        },
        mainContent: {
            padding: 0,
        },
        topContent: {
            padding: theme.spacing(2),
        },

        contentHeaderItem: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        btnCreateWrapper: {
            display: "flex",
            justifyContent: "center",
        },
        btnCreate: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.palette.primary.main,
            color: theme.colors.white,
            "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: theme.colors.white,
            },
        },
        navLoadMore: {
            backgroundColor: theme.palette.primary.main,
            color: theme.colors.white,
            height: `calc(${theme.footer.height} - 40px)`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        emptyList: {
            marginTop: theme.spacing(3),
            color: theme.palette.grey[500],
        },
    }),
);
