import { useState, useCallback, useEffect } from "react";
import { AppResponse } from "JS/Types";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { FavoritesService } from "JS/Services/Favorites";
import {
    Content,
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
    VideoContent,
} from "JS/Models";
import moment from "moment";
import { EventsHelper } from "JS/Services/Events/EventsHelper";
import { setGlobalFavorites } from "JS/Redux/Favorites";
import { getUniqueValues } from "JS/Helpers";
import { useFirebaseLogger } from "./Firebase";
import { useSnackbar } from "notistack";
import { messaging } from "JS/Helpers/UserMessaging";

const service = new FavoritesService();
const FAVORITES_KEY_LOCAL_STORAGE = "FAVORITES_SYNC_TIME";

export const useGetFavorites = (skip: boolean = false) => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] =
        useState<AppResponse<(Content | VideoContent)[]>>(null);
    const { setGlobalFavoritesVideos, setGlobalFavoritesAudios } =
        useGlobalFavorites();
    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(
        (showLoader: boolean = true, showSnackbar: boolean = false) => {
            setLoading(showLoader);
            service
                .getFavorites()
                .then((res) => {
                    const response = res.response;
                    localStorage.setItem(
                        FAVORITES_KEY_LOCAL_STORAGE,
                        moment.now().toString(),
                    );
                    setGlobalFavoritesAudios(
                        getUniqueValues(
                            response?.data &&
                                response?.data.filter(
                                    (x) =>
                                        (x.content_item_type_name === "MP3" ||
                                            x.content_item_type_name ===
                                                "GiftMP3" ||
                                            x.content_item_type_name ===
                                                "GiftProsMP3") &&
                                        x.is_favourite === true,
                                ),
                            "nid",
                        ),
                    );
                    setGlobalFavoritesVideos(
                        getUniqueValues(
                            response?.data &&
                                (response?.data.filter(
                                    (x) =>
                                        (x.content_item_type_name === "Video" ||
                                            x.content_item_type_name ===
                                                "GiftVideo" ||
                                            x.content_item_type_name ===
                                                "GiftProsVideo") &&
                                        x.is_favourite === true,
                                ) as VideoContent[]),
                            "nid",
                        ),
                    );
                    setResponse(res);
                    setLoading(false);
                    if (showSnackbar) {
                        if (response.status) {
                            enqueueSnackbar(messaging.favorites.success, {
                                variant: "success",
                            });
                        } else {
                            enqueueSnackbar(messaging.favorites.error, {
                                variant: "warning",
                            });
                        }
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    useEffect(() => {
        const syncTime = +localStorage.getItem(FAVORITES_KEY_LOCAL_STORAGE);
        const have24HoursPassed =
            moment.now() - syncTime >= EventsHelper.ONE_DAY;
        if (have24HoursPassed || !skip) refetch(!skip);
    }, [skip]);

    return {
        refetch,
        response,
        loading,
    };
};

export const useMarkFavorites = () => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<AppResponse<string[]>>(null);
    const { logFirebaseEvent } = useFirebaseLogger();

    const markFavoriteByNid = useCallback(
        (nid: string, firebaseEvent?: Partial<FirebaseEventAction>) => {
            setLoading(true);
            let successEventName: EventNames;

            const fireEvent: FirebaseEventAction = {
                ...firebaseEvent,
                action: EventActions.Favorite,
            };

            if (firebaseEvent) {
                if (firebaseEvent?.category === EventCategories.GIFT_AUDIOS) {
                    successEventName = EventNames.GIFT_AUDIO_FAVORITE;
                }

                if (firebaseEvent?.category === EventCategories.GIFT_VIDEOS) {
                    successEventName = EventNames.GIFT_VIDEO_FAVORITE;
                }

                if (firebaseEvent?.category === EventCategories.AUDIOS) {
                    successEventName = EventNames.AUDIO_FAVORITE;
                }
                if (firebaseEvent?.category === EventCategories.VIDEOS) {
                    successEventName = EventNames.VIDEO_FAVORITE;
                }
            }

            return service
                .markContentFavorites(nid)
                .then((res) => {
                    const response = res.response;
                    if (response.status) {
                        setResponse(res);
                        setLoading(false);
                        logFirebaseEvent(successEventName, fireEvent);
                    }
                    return response;
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                    return err;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        markFavoriteByNid,
        response,
        loading,
    };
};

export const useUnMarkFavorites = () => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<AppResponse<string[]>>(null);
    const { logFirebaseEvent } = useFirebaseLogger();

    const unMarkFavoriteByNid = useCallback(
        (nid: string, firebaseEvent?: Partial<FirebaseEventAction>) => {
            setLoading(true);

            let successEventName: EventNames;

            const fireEvent: FirebaseEventAction = {
                ...firebaseEvent,
                action: EventActions.UnFavorite,
            };

            if (firebaseEvent) {
                if (firebaseEvent?.category === EventCategories.GIFT_AUDIOS) {
                    successEventName = EventNames.GIFT_AUDIO_UNFAVORITE;
                }

                if (firebaseEvent?.category === EventCategories.GIFT_VIDEOS) {
                    successEventName = EventNames.GIFT_VIDEO_UNFAVORITE;
                }

                if (firebaseEvent?.category === EventCategories.AUDIOS) {
                    successEventName = EventNames.AUDIO_UNFAVORITE;
                }
                if (firebaseEvent?.category === EventCategories.VIDEOS) {
                    successEventName = EventNames.VIDEO_UNFAVORITE;
                }
            }

            return service
                .removeContentFavorites(nid)
                .then((res) => {
                    const response = res.response;
                    if (response.status) {
                        setResponse(res);
                        setLoading(false);
                        logFirebaseEvent(successEventName, fireEvent);
                    }
                    return response;
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                    return err;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        unMarkFavoriteByNid,
        response,
        loading,
    };
};

export const useGlobalFavorites = () => {
    const dispatch = useAppDispatch();

    return {
        favoritesAudios: useAppSelector((state) => state.favorites)
            ?.favoritesAudios,
        favoritesVideos: useAppSelector((state) => state.favorites)
            ?.favoritesVideos,

        setGlobalFavoritesAudios: (audios: Content[]) => {
            dispatch(
                setGlobalFavorites({
                    favoritesAudios: audios,
                }),
            );
        },

        setGlobalFavoritesVideos: (videos: VideoContent[]) => {
            dispatch(
                setGlobalFavorites({
                    favoritesVideos: videos,
                }),
            );
        },
    };
};
