import {
    Box,
    Grid,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useAudios, useGlobalAudios } from "JS/React/Hooks/Audio";
import {
    useGlobalPlaylists,
    usePlaylistAudios,
    usePlaylists,
} from "JS/React/Hooks/Playlist";
import { useEffect, useMemo, useRef, useState } from "react";
import { Layout } from "../Layout";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { messaging } from "JS/Helpers/UserMessaging";
import qs from "qs";
import { createStyles, makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { MediaPlayer } from "JS/React/Components/MediaPlayer";
import { getPublicUrl } from "JS/Helpers";
import {
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
    MiniPlayerOptions,
} from "JS/Models";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { useGlobalPlaylistControls } from "JS/React/Hooks/PlaylistControls";
import { getDexieConnectionRefresh } from "JS/Database/Dexie";
import { config } from "JS/Config";
import {
    useContentPlayingHandlers,
    usePlayerHandlers,
} from "JS/React/Hooks/MediaPlayer";
import { PlayerActionBtns } from "JS/React/Components/MediaPlayer/PlayerActionBtns";
import { useMiniPlayerHandlers } from "JS/React/Hooks/MiniPlayer";
import { useResumeRecentlyPlaylist } from "JS/React/Hooks/ResumeRecently";
import { getCurrentUrl } from "JS/Helpers/MiniPlayerHelper";
import { PLAYER_PROGRESS_INTERVAL } from "JS/Helpers/Contants";
import {
    SentGiftType,
    getPlayerPlayedTimeToLog,
    toSentGift,
} from "JS/Models/Firebase/GiftDashboard";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";
import { usePlaybackAudios } from "JS/Helpers/PlaylistHelpers";

export interface PlaylistPlayerProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const PlaylistPlayer = (props: PlaylistPlayerProps) => {
    const classes = useStyles(props);

    const [mediaURL, setMediaURL] = useState("");
    const [thumbnail, setThumbnail] = useState<string>(null);
    const [isSingleLoop, setSingleLoop] = useState(false);
    const [downloadedAudioUrl, setDownloadedAudioUrl] = useState<string>("");
    const [isAudioDownloaded, setIsAudioDownloaded] = useState<boolean>();

    const [currentPlayingState, setCurrentPlayingState] = useState(true);
    const [playingState, setPlayingState] = useState(false);
    const { handlePlayerPause, handlePlayerPlay } = useContentPlayingHandlers(
        (val) => {
            setPlayingState(val);
            setCurrentPlayingState(val);
        },
    );
    const { miniPlayer, onClickMiniPlayer } = useMiniPlayerHandlers();

    const { loading: playlistLoading } = usePlaylistAudios();
    const { audios: allAudios, isAudioUpdateRequired } = useGlobalAudios();
    const { userPlaylists: playlists } = useGlobalPlaylists();

    const { loading: audioLoading } = useAudios(
        allAudios && allAudios.length > 0,
        isAudioUpdateRequired,
    );
    const { loading: playlistsLoading } = usePlaylists(
        playlists && playlists.length > 0,
    );
    const params: any = useParams();
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { id } = params;
    const location = useLocation();

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { index = 0, bundleIndex = 0, scrollTo = 0 } = parsedQueryString;

        return {
            index: index.toString(),
            bundleIndex: bundleIndex.toString(),
            scrollTo: scrollTo,
        };
    }, [location?.search]);
    const setQueryParams = (params: Partial<typeof queryParams>) => {
        history.replace({
            pathname: location.pathname,
            search: qs.stringify({ ...queryParams, ...params }),
        });
    };
    const mediaIndex = +queryParams.index;
    const bundleIndex = +queryParams.bundleIndex;

    const playlist = useMemo(() => {
        return playlists?.find((p) => p?.playlist_id === id);
    }, [playlists, id]);

    const { playlistControls, toggleGlobalShuffle, toggleGlobalLoop } =
        useGlobalPlaylistControls();

    const {
        playlistAudios,
        currentAudio,
        rmgLoading,
        rmpLoading,
        isGift,
        getAudioByIndex,
        sortedMappedAudios,
        title,
    } = usePlaybackAudios(
        playlist,
        allAudios,
        playlistControls.shuffle,
        mediaIndex,
        bundleIndex,
        setQueryParams,
        false,
    );

    useEffect(() => {
        if (playlistAudios?.length - 1 < mediaIndex)
            setQueryParams({ index: "0", bundleIndex: "0" });
    }, [playlistAudios]);

    const {
        playerRef,
        setIsReady,
        handleContentCompleted,
        onMediaPlayerReady,
        onPlayerProgress,
        isReadyToPlay,
        setIsReadyToPlay,
    } = useResumeRecentlyPlaylist(playlistAudios, id, mediaIndex, bundleIndex);

    const { handleChangeSeek } = usePlayerHandlers(playerRef);

    const next = () => {
        setIsAudioDownloaded(null);
        const isBundle = currentAudio?.media?.length > 1;
        setIsReady(false);
        setIsReadyToPlay(false);
        setCurrentPlayingState(true);
        setQueryParams({
            index:
                isBundle && bundleIndex < currentAudio?.media?.length - 1
                    ? mediaIndex.toString()
                    : ((mediaIndex + 1) % playlistAudios?.length).toString(),
            bundleIndex:
                isBundle && bundleIndex < currentAudio?.media?.length - 1
                    ? (bundleIndex + 1).toString()
                    : "0",
        });
    };
    const previous = () => {
        setIsAudioDownloaded(null);
        const isBundle = currentAudio?.media?.length > 1;
        setIsReady(false);
        setIsReadyToPlay(false);
        let newMediaIndex = mediaIndex;
        if (mediaIndex === 0) {
            if (isBundle) {
                if (bundleIndex === 0)
                    newMediaIndex = playlistAudios?.length - 1;
                else newMediaIndex = mediaIndex;
            } else newMediaIndex = playlistAudios?.length - 1;
        } else if (!isBundle || bundleIndex === 0)
            newMediaIndex = mediaIndex - 1;
        let newBundleIndex = bundleIndex;
        if (isBundle && bundleIndex !== 0) {
            newBundleIndex = bundleIndex - 1;
        } else if (newMediaIndex !== mediaIndex) {
            const prevAudio = getAudioByIndex(newMediaIndex);
            if (prevAudio?.media?.length > 1)
                newBundleIndex = prevAudio?.media?.length - 1;
            else newBundleIndex = 0;
        }
        setCurrentPlayingState(true);
        setQueryParams({
            index: newMediaIndex?.toString(),
            bundleIndex: newBundleIndex?.toString(),
        });
    };

    const fetchThumbnails = async () => {
        const audio = currentAudio;
        const thumb = await getPublicUrl(
            audio.image_url_prefix,
            audio.image_url_postfix,
            audio.image_file_name,
        );

        setThumbnail(thumb);
    };

    const { logFirebaseEvent } = useFirebaseLogger();
    const { verifyAwsKeys } = useVerifyAwsKeys();

    const isBundle = +currentAudio?.no_of_files > 1;

    const getPlayerAction = () => {
        return getPlayerPlayedTimeToLog(
            playerRef,
            isBundle ? bundleIndex + 1 : null,
        );
    };

    const isReceivedMemberAudio = isGift(currentAudio?.nid);

    const { logGiftCompleted } = useGiftDashboard(
        toSentGift(currentAudio, bundleIndex),
        SentGiftType.AUDIO,
        "sender" in currentAudio ? currentAudio?.sender?.member_id : null,
        isReceivedMemberAudio && currentPlayingState && !miniPlayer?.url,
        getPlayerAction,
    );

    useEffect(() => {
        if (isAudioDownloaded != null) {
            if (playlistAudios && currentAudio) {
                const audio = currentAudio;
                const { nid, sku_id, title } = audio;
                const action: FirebaseEventAction = {
                    action: !isAudioDownloaded
                        ? EventActions.LIVE_STREAM
                        : EventActions.PLAY,
                    nId: nid,
                    category: audio.isReceived
                        ? EventCategories.GIFT_AUDIOS
                        : EventCategories.AUDIOS,
                    contentTitle: isBundle
                        ? audio?.media[bundleIndex]?.media_title
                        : title,
                    skuId: isBundle
                        ? currentAudio.media[mediaIndex].media_file_name
                        : sku_id,
                };

                const getEventName = () =>
                    audio.isReceived
                        ? !isAudioDownloaded
                            ? EventNames.GIFT_AUDIO_LIVESTREAM
                            : EventNames.GIFT_AUDIO_PLAYED
                        : !isAudioDownloaded
                        ? EventNames.AUDIO_LIVE_STREAM
                        : EventNames.AUDIO_PLAYED;

                logFirebaseEvent(getEventName(), action);
            }
        }
    }, [currentAudio?.nid, mediaIndex, bundleIndex, isAudioDownloaded]);

    useEffect(() => {
        if (currentAudio && "media" in currentAudio) {
            const { media_url_prefix, media_url_postfix, media_file_name } =
                currentAudio?.media[bundleIndex];
            verifyAwsKeys().then(() => {
                fetchThumbnails();
                getPublicUrl(
                    media_url_prefix,
                    media_url_postfix,
                    media_file_name,
                ).then((signedURL) => setMediaURL(signedURL));
            });
        }
    }, [currentAudio?.nid, mediaIndex, bundleIndex]);

    useEffect(() => {
        if (currentAudio) {
            const { nid } = currentAudio;
            const contentNid = isBundle ? `${nid}-${bundleIndex + 1}` : nid;

            getDexieConnectionRefresh()
                .audios.get(`${config.user.memberId}-${contentNid}`)
                .then((res) => {
                    if (res) {
                        setIsAudioDownloaded(true);
                        setDownloadedAudioUrl(URL.createObjectURL(res.blob));
                    } else {
                        setIsAudioDownloaded(false);
                    }
                })
                .catch(() => {
                    setIsAudioDownloaded(false);
                });
        }
    }, [currentAudio, bundleIndex]);

    const loading =
        playlistLoading ||
        audioLoading ||
        rmgLoading ||
        rmpLoading ||
        playlistsLoading;

    const onComplete = () => {
        handleContentCompleted();
        if (isReceivedMemberAudio) {
            let action = undefined;
            if (isBundle)
                action = {
                    index: bundleIndex + 1,
                };
            logGiftCompleted(action);
        }
        if (playlistControls.loop || mediaIndex < playlistAudios?.length - 1)
            next();
    };
    const url = isAudioDownloaded ? downloadedAudioUrl : mediaURL;

    const miniplayerOptions = useRef<MiniPlayerOptions>(null);
    const alreadyMini = useRef<boolean>(null);

    useEffect(() => {
        if (miniPlayer.url) alreadyMini.current = true;
        else alreadyMini.current = false;
    }, [miniPlayer]);

    useMemo(() => {
        if (playlistAudios)
            miniplayerOptions.current = {
                mediaUrl: url,
                thumbnail: thumbnail,
                title: title,
                singleLoop: isSingleLoop,
                isPlaying: playingState,
                parentLink: getCurrentUrl(),
                content: currentAudio,
                playlist: {
                    data: playlistAudios,
                    playlistId: id,
                    mediaIndex: mediaIndex,
                    bundleIndex: bundleIndex,
                },
            };
    }, [
        url,
        playingState,
        thumbnail,
        isSingleLoop,
        mediaIndex,
        bundleIndex,
        title,
        currentAudio,
    ]);

    const miniPlayerClick = () => {
        const options = miniplayerOptions.current;
        if (options)
            onClickMiniPlayer({
                isAudio: true,
                mediaUrl: options.mediaUrl,
                thumbnail: options.thumbnail,
                title: options.title,
                content: options.content,
                singleLoop: options.singleLoop,
                parentLink: options.parentLink,
                playlist: options.playlist,
                contentType:
                    "content_item_type_name" in currentAudio
                        ? currentAudio?.content_item_type_name
                        : "MP3",
            })();
    };

    // this useEffect is for component unmounting
    useEffect(() => {
        return () => {
            if (
                miniplayerOptions.current &&
                miniplayerOptions.current.isPlaying &&
                !alreadyMini.current
            )
                miniPlayerClick();
        };
    }, []);

    return (
        <Layout>
            <AppHeader
                title={title}
                canGoBack
                searchable={false}
                onBackClick={() => {
                    history.push(
                        linkProvider.react
                            .playlist()
                            .detail(playlist?.playlist_id, {
                                scrollTo: +queryParams?.scrollTo - 2,
                            }),
                    );
                }}
            />
            {loading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={messaging.loader.fetch_playlist}
                />
            )}
            {!loading && (
                <>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={"space-between"}
                            >
                                <Box
                                    display={"flex"}
                                    alignItems="center"
                                    className={classes.forwardBackItem}
                                    onClick={previous}
                                >
                                    <FontAwesomeIcon
                                        className={clsx(
                                            classes.fwdBackIcon,
                                            classes.backIcon,
                                        )}
                                        icon={["fas", "chevron-left"]}
                                    />
                                    <Typography
                                        className={classes.fwdBackTypo}
                                        fontWeight={"bold"}
                                        variant="h5"
                                    >
                                        Previous
                                    </Typography>
                                </Box>
                                <Box
                                    display={"flex"}
                                    alignItems="center"
                                    className={classes.forwardBackItem}
                                    onClick={next}
                                >
                                    <Typography
                                        className={classes.fwdBackTypo}
                                        fontWeight={"bold"}
                                        variant="h5"
                                    >
                                        Next
                                    </Typography>
                                    <FontAwesomeIcon
                                        className={clsx(
                                            classes.fwdBackIcon,
                                            classes.fwdIcon,
                                        )}
                                        icon={["fas", "chevron-right"]}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.playerContainer}>
                        <MediaPlayer
                            title={title}
                            playerRef={playerRef}
                            mediaURL={
                                isAudioDownloaded
                                    ? downloadedAudioUrl
                                    : mediaURL
                            }
                            imageURL={thumbnail}
                            onReady={() => onMediaPlayerReady()}
                            progressInterval={PLAYER_PROGRESS_INTERVAL}
                            onProgress={onPlayerProgress}
                            onEnded={onComplete}
                            loop={isSingleLoop}
                            isAudio={true}
                            onNext={next}
                            onPrevious={previous}
                            playing={currentPlayingState && !miniPlayer.url}
                            onPlay={handlePlayerPlay}
                            onPause={handlePlayerPause}
                            loading={!isReadyToPlay}
                            onUpdateExpiredUrl={() => {
                                setIsReady(false);
                                setIsReadyToPlay(true);
                            }}
                            onError={() => {
                                setIsReadyToPlay(true);
                            }}
                        />
                    </Grid>
                    <Grid
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={miniPlayer.url ? "end" : "center"}
                    >
                        <PlayerActionBtns
                            handleChangeSeek={handleChangeSeek}
                            isShuffle={playlistControls.shuffle}
                            isMultiLoop={playlistControls.loop}
                            isLoop={isSingleLoop}
                            onClickShuffle={() => {
                                setQueryParams({
                                    ...queryParams,
                                    index: sortedMappedAudios
                                        .findIndex(
                                            (a) => a.nid === currentAudio.nid,
                                        )
                                        .toString(),
                                });
                                toggleGlobalShuffle();
                                setIsReady(false);
                            }}
                            onClickMultiLoop={() => toggleGlobalLoop()}
                            onClickLoop={() => setSingleLoop(!isSingleLoop)}
                            onClickMiniPlayer={miniPlayerClick}
                        />
                    </Grid>
                    {playlistAudios && (
                        <Grid item xs={12} marginTop={1}>
                            <Typography
                                className={classes.grey800}
                                variant="body2"
                            >
                                {currentAudio?.description}
                            </Typography>
                        </Grid>
                    )}
                </>
            )}
        </Layout>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },

        grey500: {
            color: theme.palette.grey[500],
        },
        grey800: {
            color: theme.palette.grey[800],
        },
        greyA100: {
            color: theme.palette.grey.A100,
        },
        spinner: {
            color: theme.palette.primary.main,
        },
        icon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        constMargin: {
            marginRight: theme.spacing(2),
        },
        playerContainer: {
            margin: theme.spacing(2, 0),
            height: "60vh",
            [theme.breakpoints.down("lg")]: {
                height: "50vh",
            },
            [theme.breakpoints.down("sm")]: {
                height: "30vh",
            },
        },
        graphicIcon: {
            color: theme.palette.grey[400],
        },
        graphicWrapper: {
            border: `5px solid ${theme.palette.common.white}`,
            borderRadius: "50%",
            height: "120px",
            width: "120px",
            marginRight: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        mediaImage: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            background: theme.palette.common.black,
        },
        date: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        transactionCompletePaper: {
            color: theme.palette.grey[600],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "120px",
        },
        transactionConfirmation: {
            color: theme.palette.grey[600],
            minHeight: "120px",
        },
        giftQuanityWrapper: {
            background: `${theme.palette.grey[300]} !important`,
            color: theme.palette.grey[600],
            borderRadius: "50%",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(0.5),
            minWidth: "25px",
            minHeight: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
        },
        giftSectionWarpper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        },
        giftIconButtonDisabled: {
            opacity: "50%",
            cursor: "not-allowed !important",
        },
        giftIconPositioner: {
            position: "absolute",
            bottom: 10,
            right: 10,
        },
        metaData: {
            fontSize: "14px",
        },

        forwardBackItem: {
            padding: theme.spacing(2),
            color: theme.palette.grey.A100,
            cursor: "pointer",
        },
        fwdBackTypo: {
            fontSize: "18px",
        },
        fwdBackIcon: {
            fontSize: "18px",
        },
        backIcon: {
            marginRight: theme.spacing(2),
        },
        fwdIcon: {
            marginLeft: theme.spacing(2),
        },
        extraIconBox: {
            marginLeft: "5px",
        },
        controlIcons: {
            minWidth: "40px",
            display: "flex",
        },
        playerIcon: {
            height: "35px",
        },
    }),
);
