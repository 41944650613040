import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Box,
    Grid,
    ListItem,
    Theme,
    InternalStandardProps,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import { useGlobalPlaylists } from "JS/React/Hooks/Playlist";
import { AppTypography } from "JS/React/Components/AppTypography";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useRouting } from "JS/React/Hooks/Routes";
import { OfflinePlaylist, Playlist } from "JS/Models";
import { useGlobalGifts } from "JS/React/Hooks/Gifts";
import { useGlobalAudios } from "JS/React/Hooks/Audio";
import { isPlaylistDownloaded, sortPlaylists } from "JS/Helpers";
import { AppDivider } from "JS/React/Components/AppDivider";
import { messaging } from "JS/Helpers/UserMessaging";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        content: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            color: `${theme.palette.common.black}`,
        },
        playlistItem: {
            color: theme.palette.common.black,
        },
        disabledListItem: {
            opacity: "50%",
        },
    }),
);

export interface PlaylistProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const PlaylistListing = (props: PlaylistProps) => {
    const classes = useStyles(props);
    const { userPlaylists: playlistsInStore } = useGlobalPlaylists();
    const [playlists, setPlaylists] = useState<Playlist[]>([]);
    const [downloadedPlaylists, setDownloadedPlaylists] = useState<
        OfflinePlaylist[]
    >([]);

    const history = useHistory();
    const { linkProvider } = useRouting();
    const { audios: allAudios } = useGlobalAudios();
    const { receivedMemberAudioGifts, receivedProspectAudioGifts } =
        useGlobalGifts();

    const { enqueueSnackbar } = useSnackbar();

    useMemo(() => {
        setPlaylists(sortPlaylists(playlistsInStore));
    }, [playlistsInStore]);

    const preparePlaylistListing = async (playlists: Playlist[]) => {
        const isAudiosCache = allAudios && allAudios?.length > 0;
        let playlistData = playlists?.map((item) => {
            return {
                ...item,
                isDownloaded: false,
                isPartialDownload: false,
                isEmptyPlaylist: item?.audios?.length === 0,
                isCache: !!item.audios,
                isAudiosCache: isAudiosCache,
            };
        });
        setDownloadedPlaylists(playlistData);
        playlists?.forEach(async (playlist) => {
            const audiosInPlaylist = playlist?.audios
                ?.map((a) => a)
                ?.sort((a, b) => +a.sort_order - +b.sort_order)
                ?.map((a) => {
                    const selfAudio = allAudios.find((aa) => aa.nid === a.nid);
                    if (selfAudio) return { ...selfAudio, ...a };
                    const memberGiftAudio = receivedMemberAudioGifts.find(
                        (rmg) => rmg.nid === a.nid,
                    );
                    if (memberGiftAudio) return memberGiftAudio;
                    return receivedProspectAudioGifts.find(
                        (rgp) => rgp.nid === a.nid,
                    );
                })
                .filter((a) => a !== undefined);

            await isPlaylistDownloaded(audiosInPlaylist)
                .then((res) => {
                    if (
                        res?.isCompleteDownloaded ||
                        res?.isPartiallyDownloded
                    ) {
                        const filteredPlaylist = playlistData?.filter(
                            (x) => x.playlist_id !== playlist?.playlist_id,
                        );
                        if (filteredPlaylist?.length > 0) {
                            let data: OfflinePlaylist[] = [
                                ...filteredPlaylist,
                                {
                                    ...playlist,
                                    isDownloaded: res?.isCompleteDownloaded,
                                    isPartialDownload:
                                        res?.isPartiallyDownloded,
                                    isCache: playlist?.audios ? true : false,
                                    isEmptyPlaylist:
                                        playlist?.audios?.length === 0,
                                    isAudiosCache: isAudiosCache,
                                },
                            ];
                            data = sortPlaylists(data as Playlist[]) as any;
                            setDownloadedPlaylists(data);
                            playlistData = data;
                        }
                    }
                })
                .catch((err) => {
                    console.log({ err });
                });
        });
    };

    useEffect(() => {
        preparePlaylistListing(playlists);
    }, [
        playlists,
        allAudios,
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
    ]);

    const isGreyOut = (item) =>
        (item?.isCache && item?.isEmptyPlaylist) || !item?.isCache;

    const onPlaylistItemClick = (item: any) => {
        if (isGreyOut(item)) {
            return;
        } else if (!item?.isAudiosCache) {
            enqueueSnackbar(messaging?.playlist?.syncAudios, {
                variant: "warning",
            });
            return;
        } else if (
            item?.isAudiosCache &&
            item?.isCache &&
            !item?.isEmptyPlaylist &&
            !item?.isDownloaded &&
            !item?.isPartialDownload
        ) {
            enqueueSnackbar(messaging?.playlist?.noDownload, {
                variant: "info",
            });
            return;
        } else {
            history.push(
                linkProvider.react
                    .offline()
                    .playlist()
                    .detail(item.playlist_id),
            );
        }
    };

    return (
        <>
            <AppHeader
                title={"Audio Playlists"}
                canGoBack
                searchable={false}
                onBackClick={() =>
                    history.push(linkProvider.react.offline().index())
                }
            />
            {
                <Box>
                    {downloadedPlaylists &&
                        downloadedPlaylists.length > 0 &&
                        downloadedPlaylists?.map((item, i) => (
                            <Box
                                className={
                                    isGreyOut(item) && classes.disabledListItem
                                }
                            >
                                <Grid display={"flex"} flexDirection={"row"}>
                                    <Box key={item?.playlist_id} width={"100%"}>
                                        <ListItem
                                            onClick={(_) =>
                                                onPlaylistItemClick(item)
                                            }
                                        >
                                            <AppTypography
                                                className={classes.playlistItem}
                                            >
                                                {item.playlist_name}{" "}
                                            </AppTypography>
                                        </ListItem>
                                    </Box>
                                </Grid>
                                <AppDivider />
                            </Box>
                        ))}
                    {downloadedPlaylists && downloadedPlaylists.length === 0 && (
                        <AppTypography
                            align="center"
                            variant="h6"
                            className={classes.content}
                        >
                            {messaging.common.noItemsFound}
                        </AppTypography>
                    )}
                </Box>
            }
        </>
    );
};
