import {
    Analytics,
    getAnalytics,
    logEvent,
    isSupported,
} from "firebase/analytics";
import { FirebaseApp, initializeApp, getApps } from "firebase/app";
import {
    Auth,
    getAuth,
    inMemoryPersistence,
    setPersistence,
    signInWithCustomToken,
} from "firebase/auth";
import {
    Database,
    getDatabase,
    ref,
    get,
    set,
    onValue,
    update,
} from "firebase/database";
import { config } from "JS/Config";
import {
    Content,
    Course,
    CourseStatsBody,
    EventNames,
    FirebaseEventAction,
    User,
} from "JS/Models";
import {
    FBCourseResponse,
    FBCourseStats,
    FBFunctionStepStats,
    FBStepResponse,
    FBStepStats,
    StepActions,
} from "JS/Models/Firebase/Courses";
import moment from "moment";
import { getTimezone, isStageEnvironment } from "JS/Helpers";
import { isAndroid } from "react-device-detect";
import {
    Functions,
    HttpsCallable,
    HttpsCallableResult,
    getFunctions,
    httpsCallable,
} from "firebase/functions";
import { getFirestore, doc, Firestore, setDoc } from "firebase/firestore";
import { AudVidGiftFirestore } from "JS/Models/Firestore/AudioVideo";
import { CourseFirestore } from "JS/Models/Firestore/Courses";

export interface IAttendanceDetails {
    actionType: string;
    eventName: string;
    successMessage: string;
    failureMessage: string;
    includedSubscriptions?: string[];
    errorMessage?: string;
}

export class CourseFirebaseService {
    public app: FirebaseApp;
    private db: Database;
    private auth: Auth;
    private analytics: Analytics;
    private firestore: Firestore;
    private marketAlias = "LTD";
    private platform = isAndroid ? "android" : "iOS";
    private identifier = "Web Media App";
    private allFBFunctions: Functions;

    constructor() {
        isSupported().then((supported) => {
            if (supported) {
                const apps = getApps();
                const alreadyExist = apps?.find((x) => x.name === "[DEFAULT]");
                if (!alreadyExist) {
                    this.app = initializeApp(config.firebase.courses);
                } else {
                    this.app = alreadyExist;
                }
                this.auth = getAuth();
                if (this.app) {
                    this.db = getDatabase(this.app);
                    this.analytics = getAnalytics(this.app);
                    this.firestore = getFirestore(this.app);
                    this.allFBFunctions = getFunctions(this.app);
                }
            }
        });
    }

    async signInWithToken() {
        await signInWithCustomToken(this.auth, config.firebase.courses.token);
        await setPersistence(this.auth, inMemoryPersistence);
    }

    async initializeCourseStats(
        skuId: string,
        initialStats: Partial<FBCourseStats>,
    ): Promise<FBCourseStats> {
        const url = `Analytics/${config.user.memberId}/courses/${skuId}`;

        const dbRef = ref(this.db, url);

        const stats: Omit<FBCourseStats, "steps"> = {
            last_updated: moment().valueOf(),
            purchased_state: initialStats.purchased_state
                ? initialStats.purchased_state
                : 1,
            state: initialStats.state ? initialStats.state : 1,
            type: initialStats.type,
            title: initialStats.title,
            analytics: {
                completed_count: 0,
                completed_percentage: 0,
                completed_steps: 0,
                first_completed_date: 0,
                first_start_date: 0,
                last_completed_date: 0,
                last_opened: moment().valueOf(),
                last_step_start_date: 0,
                last_step_started: "",
                opened_count: 0,
                progress_state: 0,
                total_steps: initialStats.analytics.total_steps,
            },
        };

        await set(dbRef, stats);
        const snapshot = await get(dbRef);

        const val = snapshot.val();

        return val;
    }

    async getCourseStats(skuId: string): Promise<FBCourseStats> {
        const url = `Analytics/${config.user.memberId}/courses/${skuId}`;
        const dbRef = ref(this.db, url);

        const snapshot = await get(dbRef);
        const courseStats = snapshot.val();

        return courseStats;
    }

    async getStepStats(
        courseSkuId: string,
        stepSkuId: string,
    ): Promise<FBStepStats> {
        const dbRef = ref(
            this.db,
            `Analytics/${config.user.memberId}/courses/${courseSkuId}/steps/${stepSkuId}`,
        );

        const snapshot = await get(dbRef);

        return snapshot.val();
    }

    async observeCourseStats(
        skuId: string,
        onObserve: (stats: FBCourseStats) => void,
    ) {
        const url = `Analytics/${config.user.memberId}/courses/${skuId}`;
        const dbRef = ref(this.db, url);
        onValue(dbRef, (snapshot) => {
            onObserve(snapshot.val());
        });
    }

    async observeStepStats(
        courseSkuId: string,
        stepSkuId: string,
        onObserve: (stats: FBStepStats) => void,
    ) {
        const dbRef = ref(
            this.db,
            `Analytics/${config.user.memberId}/courses/${courseSkuId}/steps/${stepSkuId}`,
        );

        onValue(dbRef, (snapshot) => {
            onObserve(snapshot.val());
        });
    }

    async writeCourseStats(
        skuId: string,
        stats: FBCourseStats,
    ): Promise<FBCourseStats> {
        const dbRef = ref(
            this.db,
            `Analytics/${config.user.memberId}/courses/${skuId}`,
        );

        await update(dbRef, stats);

        const snapshot = await get(dbRef);

        return snapshot.val();
    }

    async writeCourseStatsOnFB(
        courseStats: CourseStatsBody,
    ): Promise<FBCourseResponse> {
        return new Promise((res, rej) => {
            const updateCourseStatsFunc: HttpsCallable<any, FBCourseResponse> =
                httpsCallable(this.allFBFunctions, "updateCourseStats");
            updateCourseStatsFunc({
                iboId: config.user.memberId,
                courseSku: courseStats.skuId,
                title: courseStats.title,
                purchasedState: courseStats.purchasedState,
                courseType: courseStats.contentType,
                steps: courseStats.steps,
            })
                .then((response) => res(response.data))
                .catch((err) => rej(err));
        });
    }

    async writeOfflineCourseStatsOnFB(
        skuId: string,
        stats: FBCourseStats,
    ): Promise<FBCourseResponse> {
        return new Promise((res, rej) => {
            const syncCourseStatsFunc: HttpsCallable<any, FBCourseResponse> =
                httpsCallable(this.allFBFunctions, "updateOfflineCourseStats");
            syncCourseStatsFunc({
                iboId: config.user.memberId,
                courseSku: skuId,
                stats,
            })
                .then((response) => res(response.data))
                .catch((err) => rej(err));
        });
    }

    async writeStepStats(
        courseSkuId: string,
        stepSkuId: string,
        action: StepActions,
        stats: Partial<FBFunctionStepStats>,
    ): Promise<HttpsCallableResult<FBStepResponse>> {
        const updateStepStatsFunc: HttpsCallable<any, FBStepResponse> =
            httpsCallable(this.allFBFunctions, "updateStepStats");
        return updateStepStatsFunc({
            skuId: courseSkuId,
            iboId: config.user.memberId,
            stepSku: stepSkuId,
            stepType: stats.content_type,
            stepTitle: stats.title,
            quiz: stats?.quiz,
            action: action,
            durationToLog: stats?.durationToLog,
            playerTime: stats?.playerTime,
            total_questions: stats?.total_questions,
        });
    }

    async writeToUpdatesMap() {
        const dbRef = ref(
            this.db,
            `course_updates_map/${config.user.memberId}`,
        );

        await set(dbRef, moment.now());
    }

    logFirebaseEvent(eventName: EventNames, action: FirebaseEventAction) {
        const toLog: any = {
            ...action,
        };

        if (action.nId) {
            toLog.nId = action.nId.toString();
        }

        if (action.position) {
            toLog.position = action.position.toString();
        }
        if (action.questionId) {
            toLog.questionId = action.questionId.toString();
        }
        if (action.receiverId) {
            toLog.receiverId = action.receiverId.toString();
        }
        if (action.skuId) {
            toLog.skuId = action.skuId.toString();
        }
        if (action.stepNId) {
            toLog.stepNId = action.stepNId.toString();
        }
        if (action.stepSkuId) {
            toLog.stepSkuId = action.stepSkuId.toString();
        }
        if (isStageEnvironment()) {
            console.log(eventName, {
                ...toLog,
                marketAlias: this.marketAlias,
                platform: this.platform,
                appVersion: config.appVersion,
                tz: getTimezone(),
                osVersion: navigator.userAgent,
                iboId: config.user.memberId.toString(),
                identifier: this.identifier,
                ts: moment().unix(),
            });
        }

        isSupported().then((supported) => {
            if (supported && this.analytics) {
                logEvent(this.analytics, eventName, {
                    ...toLog,
                    marketAlias: this.marketAlias,
                    platform: this.platform,
                    appVersion: config.appVersion,
                    tz: getTimezone(),
                    osVersion: navigator.userAgent,
                    iboId: config.user.memberId.toString(),
                    identifier: this.identifier,
                    ts: moment().unix(),
                });
            }
        });
    }

    async markAttendance(actionDetails: IAttendanceDetails): Promise<any> {
        return new Promise((res, rej) => {
            const c = httpsCallable(this.allFBFunctions, "markAttendance");
            c({
                eventName: actionDetails.eventName,
                iboId: config.user.memberId,
            })
                .then((r) => res(r))
                .catch(() => rej(actionDetails.failureMessage));
        });
    }

    getAudioVideoGiftingsDoc(skuId: Content["sku_id"]) {
        return doc(
            this.firestore,
            `gifting-details/${config.user.memberId}-current/aud-vid/${skuId}`,
        );
    }
    setAudioVideoGiftingsDoc(
        receiverId: User["member_id"],
        skuId: Content["sku_id"],
        data: AudVidGiftFirestore,
    ) {
        return setDoc(
            doc(
                this.firestore,
                `gifting-details/${config.user.memberId}-current/receivers/${receiverId}/aud-vid/${skuId}`,
            ),
            data,
            {
                merge: true,
            },
        );
    }
    setCourseGiftingsDoc(
        receiverId: User["member_id"],
        skuId: Course["sku_id"],
        data: CourseFirestore,
    ) {
        return setDoc(
            doc(
                this.firestore,
                `gifting-details/${config.user.memberId}-current/receivers/${receiverId}/courses/${skuId}`,
            ),
            data,
            {
                merge: true,
            },
        );
    }
}

export const courseFirebaseService = new CourseFirebaseService();
