import { MiniPlayerState } from "JS/Redux/MiniPlayer";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import {
    setGlobalMiniPlayer as setMiniPlayerAction,
    resetGlobalMiniPlayer,
    resetMiniPlayerOnClose,
} from "JS/Redux/MiniPlayer";
import { getCurrentUrl } from "JS/Helpers/MiniPlayerHelper";
import { Content, Gifts } from "JS/Models";
import { config } from "JS/Config";
import { getAudioById } from "JS/Database/Audio";
import { getVideoById } from "JS/Database/Video";

export const useMiniPlayerHandlers = () => {
    const { setGlobalMiniPlayer, miniPlayer } = useMiniPlayer();

    const onClickMiniPlayer =
        (options: {
            mediaUrl?: string;
            thumbnail: string;
            title: string;
            isAudio: boolean;
            parentLink?: string;
            playlist?: MiniPlayerState["playlist"];
            bundleAudio?: MiniPlayerState["bundleAudio"];
            content?: MiniPlayerState["content"];
            offlineContent?: MiniPlayerState["offlineContent"];
            recommendation?: MiniPlayerState["recommendation"];
            singleLoop?: MiniPlayerState["singleLoop"];
            contentType?: string;
        }) =>
        () => {
            const {
                isAudio,
                parentLink,
                mediaUrl,
                thumbnail,
                title,
                playlist,
                bundleAudio,
                content,
                recommendation,
                singleLoop,
                offlineContent,
                contentType,
            } = options;

            setGlobalMiniPlayer({
                url: mediaUrl,
                thumbnail: thumbnail,
                parentLink: parentLink ? parentLink : getCurrentUrl(),
                isAudio: isAudio,
                title: title,
                playlist: playlist,
                bundleAudio: bundleAudio,
                content: content,
                recommendation: recommendation,
                singleLoop: singleLoop,
                offlineContent: offlineContent,
                isPlaying: undefined,
                contentType: contentType,
            });
        };

    return {
        miniPlayer,
        onClickMiniPlayer,
    };
};

export const useMiniPlayer = () => {
    const dispatch = useAppDispatch();
    const miniPlayer = useAppSelector((x) => x.miniPlayer);

    return {
        miniPlayer,
        setGlobalMiniPlayer: (player: Partial<MiniPlayerState>) => {
            dispatch(setMiniPlayerAction(player));
        },
        resetGlobalMiniPlayer: (isPlaying?: boolean) => {
            dispatch(resetGlobalMiniPlayer(isPlaying));
        },
        resetGlobalMiniOnClose: (isPlaying?: boolean) => {
            dispatch(resetMiniPlayerOnClose(isPlaying));
        },
    };
};

export const useRebuildBlobUrl = () => {
    const rebuildBolbUrl = async (
        miniPlayer: MiniPlayerState,
    ): Promise<string | null> => {
        let isAudio: boolean;
        let indexDbLocator = "";

        if (miniPlayer) {
            if (miniPlayer?.content) {
                isAudio = miniPlayer?.isAudio;
                indexDbLocator = miniPlayer?.content.nid;
            }
            if (miniPlayer?.bundleAudio && miniPlayer?.bundleAudio?.data) {
                isAudio = true;
                const bundleData = miniPlayer?.bundleAudio?.data as
                    | Content
                    | Gifts;
                const bundleIndex = miniPlayer?.bundleAudio?.mediaIndex;

                indexDbLocator = `${bundleData.nid}-${bundleIndex}`;
            }

            if (miniPlayer?.playlist && miniPlayer?.playlist?.data) {
                isAudio = true;
                const playlistData = miniPlayer?.playlist?.data;
                const bundleIndex = miniPlayer?.playlist?.bundleIndex;

                if (
                    playlistData[miniPlayer?.playlist?.mediaIndex]?.media
                        ?.length <= 1
                ) {
                    indexDbLocator = `${
                        playlistData[miniPlayer?.playlist?.mediaIndex]?.nid
                    }`;
                } else {
                    indexDbLocator = `${
                        playlistData[miniPlayer?.playlist?.mediaIndex]?.nid
                    }-${bundleIndex}`;
                }
            }
            if (indexDbLocator) {
                if (isAudio) {
                    return await getAudioById(
                        `${config.user.memberId}-${indexDbLocator}`,
                    )
                        .then((res) => {
                            if (res) {
                                const url = URL.createObjectURL(res.blob);
                                return url;
                            } else {
                                return null;
                            }
                        })
                        .catch(() => {
                            return null;
                        });
                } else {
                    return await getVideoById(
                        `${config.user.memberId}-${indexDbLocator}`,
                    )
                        .then((res) => {
                            if (res) {
                                const url = URL.createObjectURL(res.blob);
                                return url;
                            } else {
                                return null;
                            }
                        })
                        .catch(() => {
                            return null;
                        });
                }
            }
        } else {
            return null;
        }
    };
    return {
        rebuildBolbUrl,
    };
};
