import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    Category,
    Content,
    ContentDetails,
    Gifts,
    ResumeContent,
} from "JS/Models";
import {
    AudioVideoResume,
    PlayedAudiosVideos,
} from "JS/Models/Firebase/Resume";

export interface AudioState {
    catagoriesList?: Content[];
    audiosList?: Content[];
    categories?: Category[];
    audios?: Content[];
    audioDetails?: ContentDetailsMap;
    resumeAudios?: ResumeAudiosMap;
    resumeBundles?: ResumeAudiosMap;
    playedAudios: PlayedAudiosMap;
    isUpdateRequired?: boolean;
}

export type ContentDetailsMap = {
    [key: string]: ContentDetails;
};

export type PlayedAudiosMap = {
    [key: string]: (Content | Gifts | PlayedAudiosVideos)[];
};

export type ResumeAudiosMap = {
    [key: string]: (ResumeContent | AudioVideoResume)[];
};

const initialState: AudioState = {
    catagoriesList: [],
    audiosList: [],
    playedAudios: {},
    resumeAudios: {},
    resumeBundles: {},
    audios: [],
    categories: [],
    audioDetails: {},
    isUpdateRequired: false,
};

export const audioStateSlice = createSlice({
    name: "audioState",
    initialState,
    reducers: {
        setGlobalAudios: (state, data: PayloadAction<AudioState>) => {
            state.catagoriesList = [];
            state.audiosList = [];
            state.audios = data.payload.audios;
            state.categories = data.payload.categories;
            state.playedAudios = data.payload.playedAudios;
            state.isUpdateRequired = false;
            return state;
        },
        setGlobalPlayedAudios: (
            state,
            data: PayloadAction<PlayedAudiosMap>,
        ) => {
            state = { ...state, playedAudios: { ...data.payload } };
            return state;
        },
        setUserResumeAudios: (state, data: PayloadAction<ResumeAudiosMap>) => {
            state = { ...state, resumeAudios: data.payload };
            return state;
        },
        setUserResumeBundles: (state, data: PayloadAction<ResumeAudiosMap>) => {
            state = { ...state, resumeBundles: data.payload };
            return state;
        },
        resetAudioState: (state) => {
            return initialState;
        },
        setAudioUpdateRequired: (state) => {
            return { ...state, isUpdateRequired: true };
        },
        setAudioDetails: (state, data: PayloadAction<ContentDetailsMap>) => {
            return { ...state, audioDetails: data.payload };
        },
    },
});

export const {
    setGlobalAudios,
    setGlobalPlayedAudios,
    resetAudioState,
    setUserResumeAudios,
    setUserResumeBundles,
    setAudioUpdateRequired,
    setAudioDetails,
} = audioStateSlice.actions;
