import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Box, Fab, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Layout } from "../Layout";
import {
    useGlobalGifts,
    useReceivedMemberAudioGifts,
    useReceivedProspectAudioGifts,
} from "JS/React/Hooks/Gifts";
import { useGlobalAudios, useAudios } from "JS/React/Hooks/Audio";
import { AppHeader } from "JS/React/Components/AppHeader";
import { AudioCategoryList } from "JS/React/Scenes/Audios/Components/AudioCategoryList";
import { uniqBy } from "lodash-es";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { messaging } from "JS/Helpers/UserMessaging";
import { Content, Gifts, SelectedPlaylistContent } from "JS/Models";
import { useSeamlessUpdate } from "JS/React/Hooks/Users";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useGetFavorites, useGlobalFavorites } from "JS/React/Hooks/Favorites";
import {
    useFilteredRecommendations,
    useGlobalRecommendations,
    useRecommendations,
} from "JS/React/Hooks/Recommendations";
import { AttributeType, ContentSpeakerName } from "JS/Redux/Attributes";
import { useAttributes, useGlobalAttributes } from "JS/React/Hooks/Attributes";
import { MultiAutocompleteProps } from "JS/React/Components/AppMultiTagSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SpeakerPlaylistDialog } from "./SpeakerPlaylistDialog";
import { useCreatePlaylistWithContents } from "JS/React/Hooks/Playlist";
import { sortData, sortViaAcquiredDate, sortViaPlayedDate } from "JS/Types";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useScrollWithDelay } from "JS/React/Hooks/Media";
import { useGlobalMediaEssentials } from "JS/React/Hooks/MediaEssentials";
import { ListingComponent } from "./ListingComponent";
import { useAudiosHandlers } from "./AudioHooks";
import { useAudioResumeHelper } from "./AudioHelpers";
import { useDownloadedContentStatus } from "../Videos/DownloadedContentStatus";

export interface AudiosProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export enum AudioCategoryTitle {
    ALL_AUDIOS = "all-audios",
    MY_AUDIOS = "my-audios",
    SAVED = "saved-audios",
    RECENTLY_PLAYED = "recently_played",
    RECOMMENDED = "recommended",
    INDEX = "index",
}

export const Audios = (props: AudiosProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    useSeamlessUpdate();

    const { audios, categories, isAudioUpdateRequired } = useGlobalAudios();

    const { favoritesAudios } = useGlobalFavorites();
    const { isResumable } = useAudioResumeHelper();

    useGetFavorites(!!favoritesAudios && favoritesAudios.length > 0);

    const { loading: audiosLoading } = useAudios(
        !!audios?.length,
        isAudioUpdateRequired,
    );

    const { receivedMemberAudioGifts, receivedProspectAudioGifts } =
        useGlobalGifts();
    useReceivedMemberAudioGifts();
    useReceivedProspectAudioGifts();

    const { setGlobalAudiosStack, resetGlobalAudiosStack, audiosStack } =
        useGlobalNavStack();

    const { filteredRecommendedAudios, recommendationsLoading } =
        useAudioRecommendations(audios);

    const [searchQuery, setSearchQuery] = useState(audiosStack?.searchQuery);

    const {
        audioUniqueSpeakers,
        audioSelectedSpeakers,
        attributesLoading,
        onToggleSpeakerSelect,
        audioContentSpeakers,
        handleClearFilters,
        handleResetAudioAttributes,
        mediaEssentials,
    } = useAudioAttributesHandlers();

    const [showList, setShowList] = useState(false);

    const filteredAudios = useMemo(() => {
        return uniqBy(
            [
                ...audios,
                ...receivedMemberAudioGifts,
                ...receivedProspectAudioGifts,
            ],
            (x) => x.nid,
        );
    }, [audios, receivedMemberAudioGifts, receivedProspectAudioGifts]);

    const { searchResults } = useFilterAudioHandlers(
        filteredAudios,
        searchQuery,
        audioSelectedSpeakers,
        audioContentSpeakers,
    );

    const {
        createClicked,
        handleCreateClick,
        handleClose,
        handleCreatePlaylist,
        onToggleSelectItem,
        selectedContentNids,
        finalData,
        createPlaylistLoading,
        playlistName,
        setPlaylistName,
    } = useCreatePlaylistHandlers(searchResults);

    useEffect(() => {
        setGlobalAudiosStack({
            searchQuery: searchQuery,
        });
    }, [searchResults, audioSelectedSpeakers]);

    const loaded =
        !audiosLoading && !recommendationsLoading && !attributesLoading;
    const audioListVisible =
        showList ||
        searchQuery?.length > 0 ||
        audioSelectedSpeakers?.length > 0;
    const { handleRedirectToDetail } = useAudiosHandlers(
        searchQuery,
        AudioCategoryTitle.INDEX,
    );
    const useAudiosDownloadHelperData = (data: (Content | Gifts)[]) => {
        const { getDownloadedStatusById } = useDownloadedContentStatus(
            data,
            "audio",
        );
        return getDownloadedStatusById;
    };
    useScrollWithDelay();

    return (
        <Layout>
            <div className={clsx(className, classes.root)} {...rest}>
                <AppHeader
                    title="Audios"
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchPlaceholder={"Search Audios by title/description"}
                    onSearchFieldClick={() => {
                        setShowList(true);
                    }}
                    onBackClick={() => {
                        setSearchQuery("");
                        resetGlobalAudiosStack();
                        handleResetAudioAttributes();
                        setShowList(false);
                    }}
                    canGoBack={audioListVisible}
                    filterable={mediaEssentials?.allow_speaker_feature}
                    onToggleSpeakerSelect={onToggleSpeakerSelect}
                    uniqueSpeakers={audioUniqueSpeakers}
                    selectedSpeakers={audioSelectedSpeakers}
                    handleClearFilters={handleClearFilters}
                />
                {loaded ? (
                    <>
                        {audioListVisible ? (
                            <>
                                {mediaEssentials?.allow_speaker_feature &&
                                    audioSelectedSpeakers?.length > 0 &&
                                    searchResults?.length > 0 && (
                                        <Box
                                            className={clsx(
                                                className,
                                                classes.fab,
                                            )}
                                        >
                                            <Fab
                                                color="primary"
                                                aria-label="add"
                                                className={clsx(
                                                    className,
                                                    classes.floatingButton,
                                                )}
                                                onClick={handleCreateClick}
                                            >
                                                <FontAwesomeIcon
                                                    icon={["fas", "music"]}
                                                />
                                            </Fab>
                                        </Box>
                                    )}

                                <ListingComponent
                                    listContent={searchResults as Content[]}
                                    searchQuery={searchQuery}
                                    categoryId={AudioCategoryTitle.INDEX}
                                    showSortHeader={true}
                                    setGlobalStack={setGlobalAudiosStack}
                                    globalStack={audiosStack}
                                    isResumeable={isResumable}
                                    favoritesContent={favoritesAudios}
                                    handleListItemClick={handleRedirectToDetail}
                                    getDownloadHelperData={
                                        useAudiosDownloadHelperData
                                    }
                                    controlType="audio"
                                />
                                {createClicked && (
                                    <SpeakerPlaylistDialog
                                        open={createClicked}
                                        audios={finalData}
                                        handleClose={handleClose}
                                        handleCreatePlaylist={
                                            handleCreatePlaylist
                                        }
                                        onToggleSelectItem={onToggleSelectItem}
                                        selectedContentNids={
                                            selectedContentNids
                                        }
                                        sortType={audiosStack?.sorting[0].col}
                                        playlistName={playlistName}
                                        setPlaylistName={setPlaylistName}
                                        createPlaylistLoading={
                                            createPlaylistLoading
                                        }
                                    />
                                )}
                            </>
                        ) : (
                            <AudioCategoryList
                                categorizedAudios={categories}
                                recommendations={filteredRecommendedAudios}
                            />
                        )}
                    </>
                ) : (
                    <AppBackdropProgress
                        open={true}
                        backdropText={
                            !attributesLoading
                                ? messaging.loader.audios
                                : messaging.loader.fectchingSpeakers
                        }
                    />
                )}
            </div>
        </Layout>
    );
};

const useAudioRecommendations = (audios: Content[]) => {
    const { recommendationState } = useGlobalRecommendations();

    const { loading: recommendationsLoading } = useRecommendations(
        recommendationState?.recommendations != null,
    );

    const { filteredRecommendedAudios } = useFilteredRecommendations(
        recommendationState?.recommendations,
        audios,
    );

    return {
        filteredRecommendedAudios,
        recommendationsLoading,
    };
};

export const useAudioAttributesHandlers = () => {
    const {
        audioFetchStatus,
        audioUniqueSpeakers,
        setGlobalAudAttributes,
        audioSelectedSpeakers,
        audioContentSpeakers,
        resetAudioAttributes,
    } = useGlobalAttributes();
    const { mediaEssentials } = useGlobalMediaEssentials();

    const [selectedSpeakers, setSelectedSpeakers] = useState<string[]>(
        audioSelectedSpeakers,
    );
    const { loading: attributesLoading } = useAttributes(
        AttributeType.AUDIO,
        audioFetchStatus || !mediaEssentials?.allow_speaker_feature,
    );

    const onToggleSpeakerSelect: MultiAutocompleteProps["onChange"] = (
        event,
        value,
    ) => {
        setSelectedSpeakers(value);
        setGlobalAudAttributes({ selectedSpeakers: value });
    };

    const handleClearFilters = () => {
        setSelectedSpeakers([]);
        setGlobalAudAttributes({ selectedSpeakers: [] });
    };

    const handleResetAudioAttributes = () => {
        setSelectedSpeakers([]);
        resetAudioAttributes();
    };

    return {
        mediaEssentials,
        audioUniqueSpeakers,
        setGlobalAudAttributes,
        audioSelectedSpeakers,
        attributesLoading,
        onToggleSpeakerSelect,
        audioContentSpeakers,
        handleClearFilters,
        handleResetAudioAttributes,
    };
};

export const useCreatePlaylistHandlers = (
    searchResults: (Content | Gifts)[],
) => {
    const [createClicked, setCreateClicked] = useState<boolean>(false);
    const [selectedPlaylistContent, setSelectedPlaylistContent] = useState<
        SelectedPlaylistContent[]
    >([]);
    const [playlistName, setPlaylistName] = useState<string>("");
    const { audiosStack } = useGlobalNavStack();
    const { createPlaylistWithContents, loading: createPlaylistLoading } =
        useCreatePlaylistWithContents();

    const { enqueueSnackbar } = useSnackbar();

    let { audioPlaylistSnackCount, setGlobalAudAttributes } =
        useGlobalAttributes();

    const playableContent = useMemo(() => {
        return searchResults.filter(
            (x) => x.is_purchased || x.isReceived || x.publish_free === "1",
        );
    }, [searchResults]);

    const finalData = useMemo(() => {
        if (audiosStack?.sorting[0].col === "acquired_date") {
            const newContent: (Content | Gifts)[] = playableContent?.map(
                (d) => {
                    if (d.acquired_date) {
                        return d;
                    } else {
                        if ("sender" in d) {
                            if (d.sender.sending_date) {
                                const timeStamp = moment(
                                    d.sender.sending_date_time,
                                )
                                    .unix()
                                    .toString();
                                return {
                                    ...d,
                                    acquired_date: timeStamp,
                                };
                            } else {
                                return d;
                            }
                        } else {
                            return d;
                        }
                    }
                },
            );
            return sortViaAcquiredDate(newContent, audiosStack?.sorting);
        }
        if (audiosStack?.sorting[0].col === "played")
            return sortViaPlayedDate(playableContent, audiosStack?.sorting);

        return sortData(playableContent, audiosStack?.sorting);
    }, [playableContent, audiosStack?.sorting]);

    useEffect(() => {
        setSelectedPlaylistContent(
            finalData.map((x) => {
                return {
                    nid: x.nid,
                    checked: true,
                };
            }),
        );
    }, [finalData, createClicked]);

    const handleClose = () => {
        setCreateClicked(false);
        setPlaylistName("");
    };

    const handleCreatePlaylist = async () => {
        if (
            playlistName &&
            playlistName.length <= 45 &&
            selectedPlaylistContent?.length > 0
        ) {
            await createPlaylistWithContents(
                playlistName,
                selectedPlaylistContent
                    .filter((x) => x.checked === true)
                    .map((x) => x.nid)
                    ?.slice(0, 400),
            ).then((resp) => {
                if (resp?.status) {
                    setPlaylistName("");
                    setCreateClicked(false);
                }
            });
        } else {
            if (selectedPlaylistContent?.length === 0) {
                enqueueSnackbar("Please select at-least one audio", {
                    variant: "warning",
                });
            } else {
                enqueueSnackbar(
                    !playlistName || playlistName?.length === 0
                        ? messaging?.playlist?.emptyInput
                        : messaging?.playlist?.characterInput,
                    {
                        variant: "warning",
                    },
                );
            }
        }
    };

    const onToggleSelectItem = (nid: string, checked: boolean) => {
        let tempArray = [...selectedPlaylistContent];
        if (selectedPlaylistContent.find((x) => x.nid === nid) && !checked) {
            tempArray[
                selectedPlaylistContent.findIndex((x) => x.nid === nid)
            ].checked = false;
            setSelectedPlaylistContent(tempArray);
        } else {
            tempArray[
                selectedPlaylistContent.findIndex((x) => x.nid === nid)
            ].checked = true;
            setSelectedPlaylistContent(tempArray);
        }
    };
    const selectedContentNids = useMemo(() => {
        return selectedPlaylistContent
            .filter((x) => x.checked === true)
            .map((x) => x.nid);
    }, [selectedPlaylistContent]);

    const handleCreateClick = () => {
        if (finalData?.length > 0) {
            if (!audioPlaylistSnackCount || audioPlaylistSnackCount < 3) {
                enqueueSnackbar(messaging?.playlist?.createPlaylistInfo, {
                    variant: "info",
                    autoHideDuration: 4000,
                });
                setGlobalAudAttributes({
                    playlistSnackMsgCount: !!audioPlaylistSnackCount
                        ? audioPlaylistSnackCount + 1
                        : 1,
                });
            }
            setCreateClicked(true);
        } else {
            enqueueSnackbar(messaging?.playlist?.requiredOneContent, {
                variant: "warning",
            });
        }
    };
    return {
        createClicked,
        handleCreateClick,
        handleClose,
        handleCreatePlaylist,
        onToggleSelectItem,
        finalData,
        selectedContentNids,
        createPlaylistLoading,
        playlistName,
        setPlaylistName,
    };
};

export const useFilterAudioHandlers = (
    filteredAudios: (Content | Gifts)[],
    searchQuery: string,
    audioSelectedSpeakers: string[],
    audioContentSpeakers: ContentSpeakerName[],
) => {
    const filterNids = useMemo(() => {
        return audioContentSpeakers
            ? audioContentSpeakers.filter((x) =>
                  audioSelectedSpeakers.some((z) => z === x.speakerName),
              )
            : [];
    }, [audioSelectedSpeakers, audioContentSpeakers]);

    const filteredResults = useMemo(() => {
        return filterNids.length > 0
            ? filteredAudios?.filter((d) =>
                  filterNids.some((z) => z.nid.toString() === d.nid),
              )
            : filteredAudios;
    }, [filteredAudios, filterNids]);

    const searchResults = useMemo(() => {
        return filteredResults?.filter(
            (d) =>
                d.title
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()) ||
                d.description
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()),
        );
    }, [filteredResults, searchQuery]);

    return { searchResults };
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
        },
        wrapperLoader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "90vh",
        },
        createPlaylistButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: theme.spacing(2),
        },
        plusIcon: {
            height: "20px",
            width: "20px",
            padding: theme.spacing(1),
        },
        fab: {
            position: "fixed",
            right: "10px",
            bottom: `calc(${theme.footer.height} + 10px)`,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            zIndex: "1",
        },
        floatingButton: {
            background: theme.palette.primary.main,
            "&:hover": {
                background: theme.palette.primary.main,
            },
        },
    }),
);
