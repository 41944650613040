import { Content, Gifts, VideoContent } from "JS/Models";
import { useAppSelector } from "JS/Redux/Store";
import { useEffect, useRef, useState } from "react";
import {
    AudioDownloadProps,
    VideoDownloadProps,
} from "../Audios/ListingComponent";
import {
    useGetDownloadedAudio,
    useGetDownloadedVideo,
} from "JS/React/Hooks/Database";
import { getDownloadedPartsNids, isInDownloadQueue } from "JS/Helpers";
import { config } from "JS/Config";
export const useDownloadedContentStatus = (
    data: (Content | VideoContent | Gifts)[],
    type: "audio" | "video",
) => {
    const inProgressNid = useAppSelector(
        (state) => state.download.inProgressNid,
    );
    const downloadedContentNids = useAppSelector(
        (state) => state.download.downloadedContentNids,
    );
    const inProgressRef = useRef(inProgressNid);
    const downloadedContentNidsRef = useRef(downloadedContentNids);
    const [downloadStatus, setDownloadedStatus] = useState<
        (AudioDownloadProps | VideoDownloadProps)[]
    >([]);
    const { getDownloadedAudioById } = useGetDownloadedAudio();
    const { getDownloadedVideoById } = useGetDownloadedVideo();
    const isDownloading = (nid: string) =>
        type === "audio"
            ? inProgressRef.current.includes(nid)
            : inProgressRef.current === nid;
    const isAudioDownloaded = async (audioNid: string, length: number) => {
        if (length > 1) {
            const downloadedPartsNids = await getDownloadedPartsNids(audioNid);
            return downloadedPartsNids?.length === length;
        } else {
            const response = await getDownloadedAudioById(
                `${config.user.memberId}-${audioNid}`,
            );
            return !!response;
        }
    };
    const isDownloaded = async (nid: string, length?: number) => {
        if (type === "audio") {
            return await isAudioDownloaded(nid, length || 1);
        } else {
            return await getDownloadedVideoById(
                `${config.user.memberId}-${nid}`,
            );
        }
    };
    const calculateDownloadStatus = async (
        data: (Content | VideoContent | Gifts)[],
    ) => {
        const statusPromises = data.map(async (d) => {
            const length = "no_of_files" in d ? +d.no_of_files : undefined;
            const downloaded = await isDownloaded(d.nid, length);
            const isDownloadedValue =
                type === "audio" ? downloaded : !!downloaded;
            if (type === "audio") {
                return {
                    nid: d.nid,
                    isDownloading: isDownloading(d.nid),
                    isInDownloadedQueue:
                        isInDownloadQueue(d.nid) &&
                        inProgressRef.current !== d.nid,
                    isAudioDownloaded: Promise.resolve(isDownloadedValue),
                    inProgressDownloaded:
                        downloadedContentNidsRef.current.includes(
                            `${config.user.memberId}-${d.nid}`,
                        ),
                } as AudioDownloadProps;
            } else {
                return {
                    nid: d.nid,
                    isDownloadInProgress: inProgressRef.current === d.nid,
                    isInDownloadQueue:
                        isInDownloadQueue(d.nid) &&
                        inProgressRef.current !== d.nid,
                    isDownloaded: Promise.resolve(isDownloadedValue),
                    inProgressDownloaded:
                        downloadedContentNidsRef.current.includes(
                            `${config.user.memberId}-${d.nid}`,
                        ),
                } as VideoDownloadProps;
            }
        });
        const status = await Promise.all(statusPromises);
        setDownloadedStatus(status);
    };
    const getDownloadedStatusById = (nid: string) => {
        return downloadStatus.find((x) => x.nid === nid);
    };
    useEffect(() => {
        inProgressRef.current = inProgressNid;
    }, [inProgressNid]);
    useEffect(() => {
        downloadedContentNidsRef.current = downloadedContentNids;
    }, [downloadedContentNids]);
    useEffect(() => {
        const calculate = () => {
            if (data && data.length) calculateDownloadStatus(data);
        };
        calculate();
        const statusInterval = setInterval(calculate, 15000);
        return () => clearInterval(statusInterval);
    }, [data]);
    return { getDownloadedStatusById };
};
