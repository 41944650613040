import { useCallback, useEffect, useState } from "react";
import { ResumeService } from "JS/Services/Firebase/ResumeService";
import { getWebcastUUID } from "JS/Helpers/PushNotifications";
import { useFirebaseAuth } from "./Auth";
import { useGlobalAudios } from "../Audio";
import { useGlobalVideos } from "../Video";
import { useGlobalPlaylists } from "../Playlist";
import { config } from "JS/Config";
import {
    FirestoreResumeData,
    PlayedAudiosVideos,
} from "JS/Models/Firebase/Resume";
import { isStageEnvironment, sortArrayByKey } from "JS/Helpers";
import { Content, Gifts, VideoContent } from "JS/Models";
import { useGlobalGifts } from "../Gifts";
import { appConstants } from "JS/Helpers/Contants";
import { PlayedAudiosMap, ResumeAudiosMap } from "JS/Redux/Audio";
import { ResumeVideosMap } from "JS/Redux/Video";

export const useResumeData = () => {
    const service = new ResumeService();
    const { coursesFirebaseSignIn } = useFirebaseAuth();

    const { resumeAudios, playedAudios, resumeBundles } = useGlobalAudios();
    const { resumeVideos, playedVideos } = useGlobalVideos();
    const { playlistResumeMap } = useGlobalPlaylists();
    const deviceId = getWebcastUUID();

    const getResumeDataToLog = (
        resumeData: ResumeAudiosMap | ResumeVideosMap,
        playedData: PlayedAudiosMap,
        memberId: string,
    ) =>
        resumeData[memberId]?.map((resumeItem) => {
            const playedContent =
                playedData &&
                playedData[memberId]?.find(
                    (playedItem) => playedItem?.nid === resumeItem?.nid,
                );
            return {
                nid: resumeItem?.nid,
                title: resumeItem?.title,
                lastPlayed: resumeItem?.lastPlayed,
                played: playedContent?.played ? playedContent?.played : "0",
            };
        });

    return {
        logAudioResume: async (memberId: string) => {
            await coursesFirebaseSignIn();
            service.logResumeData(
                getResumeDataToLog(resumeAudios, playedAudios, memberId) || [],
                "Audio",
                memberId,
                deviceId,
            );
        },
        logAudioBundleResume: async (memberId: string) => {
            await coursesFirebaseSignIn();
            service.logResumeData(
                getResumeDataToLog(resumeBundles, playedAudios, memberId) || [],
                "AudioBundle",
                memberId,
                deviceId,
            );
        },
        logVideoResume: async (memberId: string) => {
            await coursesFirebaseSignIn();
            service.logResumeData(
                getResumeDataToLog(
                    resumeVideos,
                    playedVideos as PlayedAudiosMap,
                    memberId,
                ) || [],
                "Video",
                memberId,
                deviceId,
            );
        },
        logPlaylistResume: async (memberId: string) => {
            await coursesFirebaseSignIn();
            service.logResumeData(
                playlistResumeMap[memberId] || [],
                "Playlist",
                memberId,
                deviceId,
            );
        },
    };
};

const useGetFirebaseResumeData = (skip: boolean) => {
    const [loading, setLoading] = useState<boolean>();
    const [isError, setIsError] = useState<boolean>(false);
    const [resumePlayedData, setResumePlayedData] =
        useState<FirestoreResumeData>();
    const { coursesFirebaseSignIn } = useFirebaseAuth();
    const service = new ResumeService();

    const onLogs = (logs: FirestoreResumeData) => {
        setLoading(false);
        setIsError(false);
        setResumePlayedData(logs);
        if (isStageEnvironment()) console.log("logs", logs);
    };

    const fetchFirestoreData = useCallback(() => {
        const getData = async () => {
            setLoading(true);
            await coursesFirebaseSignIn();
            service.getDataSnapshot(
                config?.user?.memberId,
                getWebcastUUID(),
                onLogs,
                (err) => {
                    setIsError(true);
                    setLoading(false);
                },
            );
        };
        getData();
    }, []);

    useEffect(() => {
        if (!skip) {
            fetchFirestoreData();
        }
    }, [skip]);

    return {
        loading,
        resumePlayedData,
        isError,
        fetchFirestoreData,
    };
};

export const useSyncFirebaseResumeData = () => {
    const {
        resumeUserAudios,
        resumeUserBundles,
        playedUserAudios,
        syncResumeAudios,
        syncResumeBundles,
        syncPlayedAudios,
        audios,
        setGlobalAudios,
    } = useGlobalAudios();

    const {
        videos,
        resumeUserVideos,
        playedUserVideos,
        syncResumeVideos,
        syncPlayedVideos,
        setGlobalVideos,
    } = useGlobalVideos();

    const {
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
        receivedMemberVideoGifts,
        receivedProspectVideoGifts,
        setGlobalGifts,
    } = useGlobalGifts();

    const { userPlaylistResume, syncPlaylistData } = useGlobalPlaylists();

    const syncAudiosPlayedData = (nid: string, playedTimeStamp: string) => {
        if (audios?.filter((a) => a.nid === nid).length > 0) {
            const toSaveAudios: Content[] = audios.map((a) =>
                a.nid === nid ? { ...a, played: playedTimeStamp } : a,
            );
            setGlobalAudios(toSaveAudios);
        } else if (
            receivedMemberAudioGifts?.filter((video) => video.nid === nid)
                .length > 0
        ) {
            const toSaveReceivedMembers: Gifts[] = receivedMemberAudioGifts.map(
                (g) => (g.nid === nid ? { ...g, played: playedTimeStamp } : g),
            );
            setGlobalGifts({
                receivedMemberAudioGifts: sortArrayByKey(
                    toSaveReceivedMembers,
                    "title",
                    "ASC",
                ),
            });
        } else {
            const toSaveProspectsMembers: Gifts[] =
                receivedProspectAudioGifts?.map((d) => {
                    if (d.nid === nid) {
                        return {
                            ...d,
                            played: playedTimeStamp,
                        };
                    } else {
                        return d;
                    }
                });
            setGlobalGifts({
                receivedProspectAudioGifts: sortArrayByKey(
                    toSaveProspectsMembers,
                    "title",
                    "ASC",
                ),
            });
        }
    };

    const syncVideosPlayedData = (
        contentNid: string,
        playedTimeStamp: string,
    ) => {
        if (videos?.filter((video) => video.nid === contentNid)?.length > 0) {
            const toSaveVideos: VideoContent[] = videos.map((d) => {
                if (d.nid === contentNid) {
                    return {
                        ...d,
                        played: playedTimeStamp,
                    };
                } else {
                    return d;
                }
            });
            setGlobalVideos(toSaveVideos);
        } else if (
            receivedMemberVideoGifts?.filter(
                (video) => video.nid === contentNid,
            ).length > 0
        ) {
            const toSaveReceivedMembers: Gifts[] = receivedMemberVideoGifts.map(
                (d) => {
                    if (d.nid === contentNid) {
                        return {
                            ...d,
                            played: playedTimeStamp,
                        };
                    } else {
                        return d;
                    }
                },
            );
            setGlobalGifts({
                receivedMemberVideoGifts: sortArrayByKey(
                    toSaveReceivedMembers,
                    "title",
                    "ASC",
                ),
            });
        } else {
            const toSaveProspectsMembers: Gifts[] =
                receivedProspectVideoGifts.map((d) => {
                    if (d.nid === contentNid) {
                        return {
                            ...d,
                            played: playedTimeStamp,
                        };
                    } else {
                        return d;
                    }
                });
            setGlobalGifts({
                receivedProspectVideoGifts: sortArrayByKey(
                    toSaveProspectsMembers,
                    "title",
                    "ASC",
                ),
            });
        }
    };

    const { loading, resumePlayedData, fetchFirestoreData } =
        useGetFirebaseResumeData(true);

    const syncFirebaseData = () => {
        fetchFirestoreData();
    };

    useEffect(() => {
        if (resumePlayedData && !loading) {
            if (resumeUserAudios?.length === 0)
                syncResumeAudios(resumePlayedData?.Audio);

            if (playedUserAudios?.length === 0) {
                const resumeAudioBundle = resumePlayedData?.AudioBundle
                    ? resumePlayedData?.AudioBundle
                    : [];

                const resumeAudios = resumePlayedData?.Audio
                    ? resumePlayedData?.Audio
                    : [];
                const tempPlayedData = [...resumeAudioBundle, ...resumeAudios];

                const userPlayedData: PlayedAudiosVideos[] =
                    tempPlayedData &&
                    tempPlayedData.map((x) => {
                        syncAudiosPlayedData(x.nid, x.played);
                        return {
                            nid: x.nid,
                            title: x.title,
                            played: x.played,
                        };
                    });
                syncPlayedAudios(userPlayedData);
            }
            if (resumeUserBundles?.length === 0)
                syncResumeBundles(resumePlayedData?.AudioBundle);

            if (resumeUserVideos?.length === 0)
                syncResumeVideos(resumePlayedData?.Video);
            if (playedUserVideos?.length === 0) {
                const userPlayedData: PlayedAudiosVideos[] =
                    resumePlayedData &&
                    resumePlayedData?.Video?.map((x) => {
                        syncVideosPlayedData(x.nid, x.played);
                        return {
                            nid: x.nid,
                            title: x.title,
                            played: x.played,
                        };
                    });
                syncPlayedVideos(userPlayedData);
            }
            if (userPlaylistResume?.length === 0) {
                syncPlaylistData(resumePlayedData?.Playlist);
            }
        }
    }, [resumePlayedData, loading]);

    return {
        syncFirebaseData,
    };
};
export const useMigrateResumeData = () => {
    const {
        logAudioResume,
        logVideoResume,
        logPlaylistResume,
        logAudioBundleResume,
    } = useResumeData();

    const { resumeAudios, resumeBundles } = useGlobalAudios();
    const { resumeVideos } = useGlobalVideos();
    const { playlistResumeMap } = useGlobalPlaylists();

    const migrateResumeToFirestore = () => {
        const isMigrated =
            localStorage.getItem(appConstants.localStorage.isResumeMigrated) ||
            false;
        if (!isMigrated) {
            Object.keys(resumeAudios).forEach((item) => logAudioResume(item));
            Object.keys(resumeVideos).forEach((item) => logVideoResume(item));
            Object.keys(resumeBundles).forEach((item) =>
                logAudioBundleResume(item),
            );
            Object.keys(playlistResumeMap).forEach((item) =>
                logPlaylistResume(item),
            );
        }
    };

    return {
        migrateResumeToFirestore,
    };
};
