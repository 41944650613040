import { useAppDispatch } from "JS/Redux/Store";
import { RetiredContentService } from "JS/Services/RetiredContent";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useGlobalMediaEssentials } from "./MediaEssentials";
import { RetiredContent } from "JS/Models/RetiredContent";
import { setRetiredAudios, setRetiredVideos } from "JS/Redux/RetiredContent";

export const service = new RetiredContentService();

export const useRetiredContent = (
    contentTypes: string,
    skip: boolean = false,
    isUpdateRequired: boolean = false,
) => {
    const [loading, setLoading] = useState(false);
    const [retContent, setRetContent] = useState<RetiredContent>(null);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const { mediaEssentials } = useGlobalMediaEssentials();

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getRetiredContent(contentTypes)
            .then(async (res) => {
                const responseData = res.data;

                if (!responseData?.status) {
                    enqueueSnackbar("Failed to fetch retired content", {
                        variant: "error",
                    });
                } else {
                    const data = responseData.data;
                    setRetContent(data);
                    dispatch(
                        contentTypes.includes("MP3")
                            ? setRetiredAudios(data)
                            : setRetiredVideos(data),
                    );
                }
            })
            .catch((err) => {
                enqueueSnackbar("Failed to fetch retired content 2", {
                    variant: "error",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (
            !skip ||
            (mediaEssentials?.allow_rule_engine_requests && isUpdateRequired)
        ) {
            refetch();
        }
    }, [skip, isUpdateRequired]);

    return {
        refetch,
        retContent,
        loading,
    };
};
