import { Box, Fab, Grid, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useEffect, useMemo, useState } from "react";
import { useGlobalPlaylists } from "JS/React/Hooks/Playlist";
import { useHistory, useParams } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppContentListItem } from "JS/React/Components/AppContentListItem";
import { getDownloadedAudios } from "JS/Helpers";
import { useGlobalAudios } from "JS/React/Hooks/Audio";
import { useGlobalGifts } from "JS/React/Hooks/Gifts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import {
    Content,
    EventActions,
    EventCategories,
    EventNames,
    Gifts,
} from "JS/Models";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { useGetFavorites, useGlobalFavorites } from "JS/React/Hooks/Favorites";
import { AppDivider } from "JS/React/Components/AppDivider";
import { ListingComponent } from "../../Audios/ListingComponent";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        fab: {
            position: "fixed",
            right: "10px",
            bottom: "30px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            zIndex: 2,
        },
        floatingButton: {
            background: "#808080",
            "&:hover": {
                background: "#808080",
            },
        },
        editIcons: {
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
            color: theme.palette.common.black,
        },
        list: {
            overflow: "auto",
            width: "100%",
            height: `100%`,
        },
    }),
);

export interface PlaylistDetailProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const PlaylistDetail = (props: PlaylistDetailProps) => {
    const classes = useStyles(props);
    const { className } = props;
    const { userPlaylists: playlists, playlistResumeState } =
        useGlobalPlaylists();
    const params: any = useParams();
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { id } = params;

    const {
        audios: allAudios,
        resumeUserAudios,
        resumeUserBundles,
    } = useGlobalAudios();
    const [audios, setAudios] = useState<PlaylistAudioContent[]>([]);

    // download playlist
    const { receivedMemberAudioGifts, receivedProspectAudioGifts } =
        useGlobalGifts();

    const { favoritesAudios } = useGlobalFavorites();

    useGetFavorites(!!favoritesAudios && favoritesAudios.length > 0);

    const { logFirebaseEvent } = useFirebaseLogger();

    const playlist = useMemo(() => {
        return playlists?.find((p) => p.playlist_id === id);
    }, [playlists]);

    useEffect(() => {
        if (playlist)
            logFirebaseEvent(EventNames.AUDIO_PLAYLIST_OPENED, {
                action: EventActions.OPEN,
                category: EventCategories.AUDIO_PLAYLIST,
                playlistId: playlist.playlist_id,
            });
    }, [playlist?.playlist_id]);

    const preparePlaylistAudios = async () => {
        const audiosInPlaylist = playlist?.audios
            ?.map((a) => a)
            ?.sort((a, b) => +a.sort_order - +b.sort_order)
            ?.map((a) => {
                const selfAudio = allAudios.find((aa) => aa.nid === a.nid);
                if (selfAudio) return { ...selfAudio, ...a };
                const memberGiftAudio = receivedMemberAudioGifts.find(
                    (rmg) => rmg.nid === a.nid,
                );
                if (memberGiftAudio) return memberGiftAudio;
                return receivedProspectAudioGifts.find(
                    (rgp) => rgp.nid === a.nid,
                );
            })
            .filter((a) => a !== undefined);

        await getDownloadedAudios(audiosInPlaylist)
            .then((res) => {
                if (res?.length) {
                    const data = audiosInPlaylist.map((a) => {
                        const downloadedContent = res?.find(
                            (r) => r.nid === a.nid,
                        );
                        let audioObj = {};
                        if (downloadedContent) {
                            audioObj = {
                                ...downloadedContent,
                                isDownloaded: true,
                            };
                        } else {
                            audioObj = {
                                ...a,
                                isDownloaded: false,
                            };
                        }
                        return audioObj as PlaylistAudioContent;
                    });
                    setAudios(data);
                }
            })
            .catch((err) => {
                console.log({ err });
            });
    };
    useEffect(() => {
        preparePlaylistAudios();
    }, [
        playlist,
        allAudios,
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
    ]);

    const loading = false;

    const isResumeable = (value: Content | Gifts) => {
        if (+value.no_of_files > 1) {
            let filterResumeBundle = resumeUserBundles?.filter(
                (x) => x.nid === value.nid,
            );
            if (filterResumeBundle?.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            if (
                resumeUserAudios &&
                resumeUserAudios.filter((x) => x.nid === value.nid).length > 0
            ) {
                const resumeData = resumeUserAudios.find(
                    (x) => x.nid === value.nid,
                );
                return !!resumeData;
            } else {
                return false;
            }
        }
    };

    const playPlaylist = () => {
        logFirebaseEvent(EventNames.AUDIO_PLAYLIST_PLAYED, {
            category: EventCategories.AUDIO_PLAYLIST,
            action: EventActions.PLAY,
            playlistId: id,
        });
        history.push(
            linkProvider.react
                .offline()
                .playlist()
                .play(id, {
                    index: playlistResumeState(
                        playlist,
                        audios,
                    )?.resumeAudioIndex?.toString(),
                    bundleIndex: playlistResumeState(
                        playlist,
                        audios,
                    )?.resumeBundleIndex?.toString(),
                }),
        );
    };
    const onItemClick = (value, _, itemIndex: number) => {
        history.push(
            linkProvider.react
                .offline()
                .playlist()
                .play(id, {
                    index: audios?.indexOf(value).toString(),
                    bundleIndex: "0",
                    scrollTo: value.nid,
                }),
        );
    };
    return (
        <>
            <AppHeader
                title={playlist?.playlist_name}
                canGoBack
                searchable={false}
                onBackClick={() => {
                    history.push(
                        linkProvider.react.offline().playlist().index(),
                    );
                }}
            />
            <Box className={clsx(className, classes.fab)}>
                <Fab
                    color="success"
                    aria-label="add"
                    onClick={playPlaylist}
                    className={clsx(className, classes.floatingButton)}
                >
                    <FontAwesomeIcon icon={["fas", "play"]} />
                </Fab>
            </Box>
            {loading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={messaging.loader.fetch_playlist}
                />
            )}
            {!loading && (
                <Grid className={clsx(classes, classes.list)}>
                    <ListingComponent
                        listContent={audios}
                        showSortHeader={false}
                        isResumeable={isResumeable}
                        favoritesContent={favoritesAudios}
                        handleListItemClick={onItemClick}
                        controlType="offlinePlaylist"
                    />
                </Grid>
            )}
        </>
    );
};

export type PlaylistAudioContent =
    | (Content | Gifts) & {
          isDownloaded: boolean;
      };
