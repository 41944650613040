import {
    MenuList,
    MenuItem,
    ListItemText,
    Typography,
    Theme,
    Box,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { GiftsTypes } from "JS/Models";
import { AppDivider } from "JS/React/Components/AppDivider";
import { AppTypography } from "JS/React/Components/AppTypography";
import { RenderIf } from "JS/React/Components/Wrapper/RenderIf";

interface AttachSku {
    title: GiftsTypes;
    sku: string;
    quantity: number;
}

export interface AttachSkuMenuProps {
    skus: AttachSku[];
    loaded: boolean;
    onItemClick: (itemTitle: GiftsTypes, sku: string) => void;
}

export const AttachSkuMenu = (props: AttachSkuMenuProps) => {
    const { skus, loaded, onItemClick } = props;
    const classes = useStyles(props);

    return (
        <>
            {skus?.map((sku, index) => (
                <MenuList key={`${index}`}>
                    {index !== 0 && <AppDivider />}
                    <MenuItem onClick={() => onItemClick(sku.title, sku.sku)}>
                        <ListItemText>{sku.title}</ListItemText>
                        <Box />
                        <Typography variant="body2" color="text.secondary">
                            <RenderIf isTrue={loaded}>
                                <AppTypography
                                    variant="body2"
                                    className={classes.giftQuanityWrapper}
                                >
                                    {sku.quantity}
                                </AppTypography>
                            </RenderIf>
                        </Typography>
                    </MenuItem>
                </MenuList>
            ))}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        giftQuanityWrapper: {
            background: `${theme.palette.grey[300]} !important`,
            color: theme.palette.grey[600],
            borderRadius: "50%",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(0.5),
            minWidth: "25px",
            minHeight: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
        },
    }),
);

export const convertToAttachSkuArray = (
    memberSku: string,
    memberSkuQuantity: number,
    prospectSku: string,
    prospectSkuQuantity: number,
) => {
    const skuArray = [];
    if (!!memberSku)
        skuArray.push({
            title: GiftsTypes.MEMBER,
            sku: memberSku,
            quantity: memberSkuQuantity,
        });
    if (!!prospectSku)
        skuArray.push({
            title: GiftsTypes.PROSPECT,
            sku: prospectSku,
            quantity: prospectSkuQuantity,
        });
    return skuArray;
};
