import clsx from "clsx";
import {
    Box,
    Grid,
    GridProps,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Content } from "JS/Models";
import { AppTypography } from "JS/React/Components/AppTypography";
import FallbackWaveform from "Images/Content/FallbackWaveform.png";
import { RecommendationBtn } from "./RecommendationsDialog";
import { contentItemTypeNameToString } from "JS/Helpers";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";

export interface RecommendationListItemProps
    extends InternalStandardProps<GridProps> {
    audio: Content;
    thumbnail: string;
    onBtnClick: (action: RecommendationBtn) => void;
    redeeming: boolean;
}

export const RecommendationListItem = (props: RecommendationListItemProps) => {
    const classes = useStyles(props);

    const {
        className,
        thumbnail,
        audio: content,
        redeeming,
        onBtnClick,
        ...rest
    } = props;
    const { btnText, showCredits } = useVariables(content);

    const onAction = (e) => {
        e.stopPropagation();
        onBtnClick(btnText);
    };

    return (
        <Grid container className={clsx(className, classes.root)} {...rest}>
            <Grid
                item
                xs={2}
                display="flex"
                justifyContent="center"
                alignItems={"center"}
            >
                <img
                    src={thumbnail || FallbackWaveform}
                    className={classes.thumbnail}
                    alt="thumbnail"
                />
            </Grid>
            <Grid item xs={10} className={classes.gridCenterItem}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <AppTypography
                        className={clsx(classes.title, classes.grey600)}
                    >
                        {content.title}
                    </AppTypography>
                    {redeeming && btnText === "Redeem" ? (
                        <AppCircularProgress
                            className={classes.actionText}
                            loaderSize="small"
                        />
                    ) : (
                        <AppTypography
                            className={clsx(
                                classes.actionText,
                                classes.grey600,
                            )}
                            fontWeight={"bold"}
                            variant="body1"
                            onClick={onAction}
                        >
                            {btnText}
                        </AppTypography>
                    )}
                </Box>
                <AppTypography className={classes.skuId}>
                    {content.sku_id} | {"AUDIO"}{" "}
                    {showCredits && <>| {content.number_of_tokens} credit(s)</>}
                </AppTypography>
                <AppTypography
                    className={clsx(
                        classes.clampText,
                        classes.grey600,
                        classes.description,
                    )}
                >
                    {content.description}
                </AppTypography>
            </Grid>
        </Grid>
    );
};

const useVariables = (content: Content) => {
    const isPlayable =
        content.is_purchased || content.isReceived || content.publish_free;

    const btnText: RecommendationBtn = isPlayable
        ? "Play"
        : content.is_redeemable_by_tokens
        ? "Redeem"
        : "Buy";

    const showCredits = !isPlayable && content.is_redeemable_by_tokens;

    return {
        isPlayable,
        btnText,
        showCredits,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2, 1.5),
        },
        gridCenterItem: {
            padding: theme.spacing(0, 1),
        },
        description: {
            fontSize: "12px",
        },
        clampText: {
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
        },
        title: {
            fontSize: "18px",
            marginBottom: "5px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        skuId: { fontSize: "14px", color: theme.palette.grey[500] },
        grey600: {
            color: theme.palette.grey[600],
        },
        thumbnail: {
            width: "60px",
            height: "auto",
            objectFit: "contain",
        },
        actionText: {
            marginLeft: theme.spacing(1),
        },
    }),
);
