import { config } from "JS/Config";
import { BaseService } from "../BaseService";
import { RetContentApiResponse } from "JS/Models/RetiredContent";

export class RetiredContentService extends BaseService {
    async getRetiredContent(contentTypes: string) {
        return await this.doXHR<RetContentApiResponse>({
            url: this.routes.server.api.users.getRetiredContent(
                config?.user?.memberId,
                contentTypes,
            ),
            method: "GET",
            headers: {
                authorization: `Bearer ${config?.accessToken}`,
            },
        });
    }
}
