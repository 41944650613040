import { config } from "JS/Config";
import { Content, VideoContent } from "JS/Models";
import { AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";

export class FavoritesService extends BaseService {
    async getFavorites() {
        const formData = new FormData();

        formData.append("access_token", `${config?.accessToken}`);

        return (
            await this.doXHR<AppResponse<(VideoContent)[]>>({
                url: this.routes.server.api.favorites.getFavorites(),
                method: "POST",
                data: formData,
            })
        ).data;
    }

    async markContentFavorites(nid: string) {
        const formData = new FormData();

        formData.append("access_token", `${config?.accessToken}`);
        formData.append("content_id", `${nid}`);

        return (
            await this.doXHR<AppResponse<string[]>>({
                url: this.routes.server.api.favorites.markContentFavorites(),
                method: "POST",
                data: formData,
            })
        ).data;
    }

    async removeContentFavorites(nid: string) {
        const formData = new FormData();
        formData.append("access_token", `${config?.accessToken}`);
        formData.append("content_id", `${nid}`);

        return (
            await this.doXHR<AppResponse<string[]>>({
                url: this.routes.server.api.favorites.removeContentFavorites(),
                method: "POST",
                data: formData,
            })
        ).data;
    }
}
