import { useEffect } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    BoxProps,
    IconButton,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { PlayerSeekAction } from "JS/Helpers";
import PictureInPictureAltIcon from "@mui/icons-material/PictureInPictureAlt";
import RepeatOneIcon from "@mui/icons-material/RepeatOne";
import RepeatOneOn from "@mui/icons-material/RepeatOneOn";
import forward30Icon from "Images/Content/forward-30.png";
import forward2mIcon from "Images/Content/forward-2m.png";
import rewind30Icon from "Images/Content/rewind-30.png";
import rewind15Icon from "Images/Content/rewind-15.png";
import forward30WIcon from "Images/Content/forward-30-w.png";
import forward2mWIcon from "Images/Content/forward-2m-w.png";
import rewind30WIcon from "Images/Content/rewind-30-w.png";
import rewind15WIcon from "Images/Content/rewind-15-w.png";
import Shuffle from "@mui/icons-material/Shuffle";
import ShuffleOn from "@mui/icons-material/ShuffleOn";
import Repeat from "@mui/icons-material/Repeat";
import RepeatOn from "@mui/icons-material/RepeatOn";
import { useMiniPlayer } from "JS/React/Hooks/MiniPlayer";
import { getCurrentUrl } from "JS/Helpers/MiniPlayerHelper";
import { useThemeModeContext } from "JS/React/Context/ThemeModeProvider";

export const PlayerActionBtns = (props: PlayerActionBtnsProps) => {
    const classes = useStyles(props);
    const {
        isLoop,
        isShuffle,
        isMultiLoop,
        handleChangeSeek,
        onClickLoop,
        onClickMiniPlayer,
        onClickShuffle,
        onClickMultiLoop,
    } = props;

    const { miniPlayer, setGlobalMiniPlayer } = useMiniPlayer();
    const themeMode = useThemeModeContext();

    useEffect(() => {
        if (getCurrentUrl() === miniPlayer.parentLink) {
            setGlobalMiniPlayer({
                singleLoop: isLoop,
            });
        }
    }, [isLoop]);

    if (onClickMiniPlayer && !!miniPlayer.url) {
        return (
            <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"end"}
                className={classes.controlIcons}
            >
                <IconButton onClick={onClickMiniPlayer}>
                    <PictureInPictureAltIcon className={classes.playerIcon} />
                </IconButton>
            </Box>
        );
    }

    return (
        <Box display="flex">
            <Box className={classes.controlIcons}>
                <IconButton
                    onClick={() =>
                        handleChangeSeek(PlayerSeekAction.REWIND, 30)
                    }
                    className={classes.iconPadding}
                >
                    <img
                        src={
                            themeMode.mode === "light"
                                ? rewind30Icon
                                : rewind30WIcon
                        }
                        alt="rewind 30 Sec"
                        className={classes.playerIcon}
                    />
                </IconButton>
            </Box>
            <Box className={classes.controlIcons}>
                <IconButton
                    onClick={() =>
                        handleChangeSeek(PlayerSeekAction.REWIND, 15)
                    }
                    className={classes.iconPadding}
                >
                    <img
                        src={
                            themeMode.mode === "light"
                                ? rewind15Icon
                                : rewind15WIcon
                        }
                        alt="rewind 15 Sec"
                        className={classes.playerIcon}
                    />
                </IconButton>
            </Box>
            {onClickShuffle && (
                <Box className={classes.controlIcons} onClick={onClickShuffle}>
                    <IconButton className={classes.iconPadding}>
                        {isShuffle ? (
                            <ShuffleOn
                                fontSize={"large"}
                                className={classes.playerIcon}
                            />
                        ) : (
                            <Shuffle
                                fontSize={"large"}
                                className={classes.playerIcon}
                            />
                        )}
                    </IconButton>
                </Box>
            )}

            {onClickMultiLoop && (
                <Box
                    className={classes.controlIcons}
                    onClick={onClickMultiLoop}
                >
                    <IconButton className={classes.iconPadding}>
                        {isMultiLoop ? (
                            <RepeatOn
                                fontSize={"large"}
                                className={classes.playerIcon}
                            />
                        ) : (
                            <Repeat
                                fontSize={"large"}
                                className={classes.playerIcon}
                            />
                        )}
                    </IconButton>
                </Box>
            )}
            {onClickLoop && (
                <Box onClick={onClickLoop} className={classes.iconPadding}>
                    <IconButton className={classes.iconPadding}>
                        {isLoop ? (
                            <RepeatOneOn
                                fontSize={"large"}
                                className={classes.playerIcon}
                            />
                        ) : (
                            <RepeatOneIcon
                                fontSize={"large"}
                                className={classes.playerIcon}
                            />
                        )}
                    </IconButton>
                </Box>
            )}
            <Box className={classes.controlIcons}>
                <IconButton
                    onClick={() =>
                        handleChangeSeek(PlayerSeekAction.FORWARD, 30)
                    }
                    className={classes.iconPadding}
                >
                    <img
                        src={
                            themeMode.mode === "light"
                                ? forward30Icon
                                : forward30WIcon
                        }
                        alt="forward 30 Sec"
                        className={classes.playerIcon}
                    />
                </IconButton>
            </Box>
            <Box className={classes.controlIcons}>
                <IconButton
                    onClick={() =>
                        handleChangeSeek(PlayerSeekAction.FORWARD, 120)
                    }
                    className={classes.iconPadding}
                >
                    <img
                        src={
                            themeMode.mode === "light"
                                ? forward2mIcon
                                : forward2mWIcon
                        }
                        alt="forward 120 Sec"
                        className={classes.playerIcon}
                    />
                </IconButton>
            </Box>
            {onClickMiniPlayer && (
                <Box className={classes.controlIcons}>
                    <IconButton
                        onClick={onClickMiniPlayer}
                        className={classes.iconPadding}
                    >
                        <PictureInPictureAltIcon
                            className={classes.playerIcon}
                        />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        controlIcons: {
            maxWidth: "40px",
            display: "flex",
            color: theme.palette.grey[600],
        },
        playerIcon: {
            height: "35px",
            color: theme.palette.grey[600],
        },
        iconPadding: {
            padding: "8px 0",
        },
    }),
);

export interface PlayerActionBtnsProps extends InternalStandardProps<BoxProps> {
    isLoop?: boolean;
    isMultiLoop?: boolean;
    isShuffle?: boolean;
    onClickLoop?: () => void;
    onClickMultiLoop?: () => void;
    onClickShuffle?: () => void;
    handleChangeSeek: (seekType: PlayerSeekAction, seekTime: number) => void;
    onClickMiniPlayer?: () => void;
}
